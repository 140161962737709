// =========== GET =========== //
export const getNotificationsEndpoint = () => {
  return `/notification`;
};

// =========== POST =========== //
export const postSkiNotificationsEndpoint = () => {
  return `/notification/ski`;
};

export const postParaglidingNotificationsEndpoint = () => {
  return `/notification/paragliding`;
};

export const postOtherActivitiesNotificationsEndpoint = () => {
  return `/notification/otherActivities`;
};

export const postContactNotificationsEndpoint = () => {
  return `/notification/contact`;
};
