import classes from "./mediaCardsWrapper.module.css";

type MediaCardsWrapperProps = {
  children: React.ReactNode;
};

const MediaCardsWrapper: React.FC<MediaCardsWrapperProps> = ({ children }) => {
  return <section className={classes.mediaCardsWrapper}>{children}</section>;
};

export default MediaCardsWrapper;
