import { Flex } from "antd";
import { MdDelete, MdEdit } from "react-icons/md";
import classes from "./ActionsOnEditModal.module.css";

type ActionsOnEditModalT = {
  onEditClick: () => void;
  onDeleteClick: () => void;
};

export const ActionsOnEditModal = ({
  onEditClick,
  onDeleteClick,
}: ActionsOnEditModalT) => {
  return (
    <Flex align="center" gap={16}>
      <div className={classes.icon}>
        <MdEdit onClick={onEditClick} />
      </div>
      <div className={classes.icon}>
        <MdDelete onClick={onDeleteClick} />
      </div>
    </Flex>
  );
};
