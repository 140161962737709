import { FormItemT } from "../../../../../types/common-types";
import { FormItemsProps } from "../../../../../types/component-types";
import { Button, Flex, Input } from "antd";
import React from "react";

const FormItems: React.FC<FormItemsProps> = ({
  _id,
  isEdit,
  itemValues,
  loading,
  description,
  setItemValue,
  onEditConfirm,
  handleSetEdit,
}) => {
  const handleItemValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setItemValue) {
      const { value } = e.target;
      setItemValue(
        (prev) =>
          ({
            ...prev,
            description: value,
          } as FormItemT)
      );
    }
  };

  const editable = isEdit === _id;

  return (
    <Flex align="center" gap={12}>
      <Flex align="center" gap={8}>
        {editable ? (
          <Input
            value={
              itemValues && editable ? itemValues.description : description
            }
            onChange={handleItemValueChange}
          />
        ) : (
          <h4>
            {itemValues && editable ? itemValues.description : description}
          </h4>
        )}
      </Flex>

      <Flex gap={8} align="center">
        <Button onClick={handleSetEdit}>{editable ? "Cancel" : "Edit"}</Button>
        {editable && (
          <Button type="primary" onClick={onEditConfirm} loading={loading}>
            Confirm
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default FormItems;
