import React, { useEffect, useState } from "react";
import { GetSubscribeResT, messageResT } from "../../api";
import { useApiRequest } from "src/hooks/useApiRequests";
import {
  deleteSubscribe,
  getSubscribes,
} from "src/api/services/subscribeServices";
import { Button, Flex, Skeleton, Table, TableProps } from "antd";
import useMessages from "src/hooks/useMessages";
import { deleteModal } from "../DeleteModal";

type ColumnsT = {
  key: string;
  email: string;
};

const SubscribedEmails = () => {
  const [data, setData] = useState<GetSubscribeResT[]>();

  const { success, error, contextHolder } = useMessages();

  const [, makeGetRequest] = useApiRequest<GetSubscribeResT[]>();
  const [, makeDeleteREquest] = useApiRequest<messageResT>();

  const getData = () => {
    makeGetRequest(getSubscribes(), (e) => {
      setData(e);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = (id: string) => {
    try {
      makeDeleteREquest(deleteSubscribe(id), (e) => {
        success(e.message);
        getData();
      });
    } catch (e) {
      error("Something went wrong");
    }
  };

  const onDeleteConfirm = (id: string) => {
    deleteModal(
      () => handleDelete(id),
      "Are you sure you want to delete this email?"
    );
  };

  if (!data) return <Skeleton active />;

  const dataSource = data.map((e) => ({
    key: e._id,
    email: e.email,
  }));

  const columns: TableProps<ColumnsT>["columns"] = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          danger
          onClick={() => onDeleteConfirm(record.key)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Flex vertical gap={32}>
        <h1>Subscribed Emails</h1>

        <Table dataSource={dataSource} columns={columns} />
      </Flex>
    </>
  );
};

export default SubscribedEmails;
