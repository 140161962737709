import { useEffect, useState } from "react";
import { Flex, Table } from "antd";
import { Chart } from "react-google-charts";
import { SkiSchoolT } from "../SkiSchool/skiTypes/skiTypes";
import { OtherActivitiesT } from "../OtherActivities/OtherActivities";
import { ParaglidingT } from "../Paragliding/Paragliding";
import { ContactT } from "../Contact/contactComponent";
import { AmountsCard } from "./AmountsCard";
import { NumberOfOrdersCard } from "./NumberOfOrdersCard";
import { MdOutlineSnowmobile } from "react-icons/md";
import { LiaSkiingSolid } from "react-icons/lia";
import { PiParachuteDuotone } from "react-icons/pi";
import { FiUsers } from "react-icons/fi";
import { TableWrapper } from "../TableWrapper";
import { recentOrdersColumns } from "./TableColumns/TableColumns";
import { DashboardSkeleton } from "./Skeleton";
import classes from "./dashboard.module.css";

const initialInfo: {
  contactmessages: ContactT[];
  otherActivitiesBookings: OtherActivitiesT[];
  paraglidingBookings: ParaglidingT[];
  skischoolBookings: SkiSchoolT[];
} = {
  contactmessages: [],
  otherActivitiesBookings: [],
  paraglidingBookings: [],
  skischoolBookings: [],
};

// testing data
const incomeData = [
  ["Activity", "Income"],
  ["Ski School", 11],
  ["Paragliding", 5],
  ["Snow Mobile", 4],
  ["Transfer and Tours", 2],
  ["Horse Riding", 8],
  ["Quad Bike", 7],
];

const incomeOptions = {
  title: "Total Income per Activity $ Test",
};

// testing data
export const quantityData = [
  ["Month", "Orders quantity"],
  ["Dec 2023", 300],
  ["Jan 2024", 350],
  ["Feb 2024", 390],
  ["Mar 2024", 400],
];

export const quantityOptions = {
  title: "Ride Gudauri Performance Test",
  curveType: "function",
  legend: { position: "bottom" },
};

const Dashboard = () => {
  const [allInfo, setAllInfo] = useState<typeof initialInfo>(initialInfo);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [allTimeAmount, setAllTimeAmount] = useState({
    usd: { skiSchool: 0, paragliding: 0, otherActivities: 0 },
    lari: { skiSchool: 0, paragliding: 0, otherActivities: 0 },
  });

  useEffect(() => {
    fetch("https://api.ridegudauri.ge/api/all")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setAllInfo(data);
        setLoading(false);
      })
      .catch((error: Error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  // console.log(allInfo);

  // ------------------------------------setting all time amounts-------------------------------
  useEffect(() => {
    let skiTotalUSD = 0;
    let skiTotalLari = 0;
    let paraglidingTotalUSD = 0;
    let paraglidingTotalLari = 0;
    allInfo.skischoolBookings.map((item) => {
      if (!item.currency) return;
      if (item.currency.usd && item.currency.gel) {
        skiTotalUSD += item.currency.usd;
        skiTotalLari += item.currency.gel;
        return { skiTotalUSD, skiTotalLari };
      }

      let dollar = 0;
      let lari = 0;
      if (item.currency.toString().includes("$")) {
        dollar += Number(item.currency.toString().replace("$", ""));
        return dollar;
      }

      lari += Number(item.currency.toString().replace("₾", ""));

      return lari;
      // console.log(item.currency);
    });
    allInfo.paraglidingBookings.map((item) => {
      // currency is missing !!!
      // const paraglidingAmountUSD = item.currency.includes("$")
      //   ? parseFloat(item.currency)
      //   : 0;
      // paraglidingTotalUSD += paraglidingAmountUSD;
      // const paraglidingAmountLari = item.currency.includes("₾")
      //   ? parseFloat(item.currency)
      //   : 0;
      // paraglidingTotalLari += paraglidingAmountLari;
      // return { paraglidingTotalUSD, paraglidingTotalLari };
    });
    setAllTimeAmount((prev) => ({
      ...prev,
      usd: {
        ...prev.usd,
        skiSchool: skiTotalUSD,
        paragliding: paraglidingTotalUSD,
      },
      lari: {
        ...prev.lari,
        skiSchool: skiTotalLari,
        paragliding: paraglidingTotalLari,
      },
    }));
  }, [allInfo]);
  // console.log(allTimeAmount.usd.skiSchool, allTimeAmount.lari.skiSchool);
  // -----------------------------------------------------------------------------------------------

  if (loading) {
    return <DashboardSkeleton />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const numberOfTotalOrders = [
    {
      id: 1,
      icon: <LiaSkiingSolid />,
      title: "Ski School Total Orders",
      numberOfOrders: allInfo ? allInfo?.skischoolBookings.length : 0,
    },
    {
      id: 2,
      icon: <PiParachuteDuotone />,
      title: "Paragliding Total Orders",
      numberOfOrders: allInfo ? allInfo?.paraglidingBookings.length : 0,
    },
    {
      id: 3,
      icon: <MdOutlineSnowmobile />,
      title: "Other Activities Total Orders",
      numberOfOrders: allInfo ? allInfo?.otherActivitiesBookings.length : 0,
    },
    {
      id: 4,
      icon: <FiUsers />,
      title: "Total contact Messages",
      numberOfOrders: allInfo ? allInfo?.contactmessages.length : 0,
    },
  ];

  const tableDataTest: typeof allInfo.otherActivitiesBookings =
    allInfo?.otherActivitiesBookings.map((item) => ({
      key: item._id,
      _id: item._id,
      type: item.type,
      fullName: item.fullName,
      number: item.number,
      email: item.email,
      participants: item.participants,
      date: item.date.split("T")[0],
      time: item.time,
      selector: item.selector,
      additionalDetails: item.additionalDetails,
      // currency: item.currency,
    }));

  return (
    <Flex vertical gap={48}>
      <Flex vertical gap={36}>
        <h2>Dashboard Overview</h2>
        <div className={classes.cardsWrapper}>
          <AmountsCard
            title="Today Orders"
            amountUSD="$0"
            amountLari="0₾"
            color="green"
          />
          <AmountsCard
            title="Yesterday Orders"
            amountUSD="$0"
            amountLari="0₾"
            color="orange"
          />
          <AmountsCard
            title="This Month"
            amountUSD="$0"
            amountLari="0₾"
            color="teal"
          />
          <AmountsCard
            title="All-Time Sales"
            amountUSD={`$${
              0
              // allTimeAmount.otherActivities +
              // allTimeAmount.paragliding +
              // allTimeAmount.skiSchool
            }`}
            amountLari="0₾"
            color="blue"
          />
        </div>
        <div className={classes.cardsWrapper}>
          {numberOfTotalOrders.map((item) => (
            <NumberOfOrdersCard
              key={item.id}
              icon={item.icon}
              title={item.title}
              numberOfOrders={item.numberOfOrders}
            />
          ))}
        </div>
      </Flex>

      <div className={classes.charts}>
        <Chart
          chartType="LineChart"
          width="99%"
          height="400px"
          data={quantityData}
          options={quantityOptions}
        />
        <Chart
          chartType="PieChart"
          data={incomeData}
          options={incomeOptions}
          width="99%"
          height="400px"
        />
      </div>

      <Flex vertical gap={36}>
        <h2>Recent Orders Test</h2>
        <TableWrapper>
          <Table
            bordered
            scroll={{ x: "calc(700px + 30%)" }}
            pagination={{ pageSize: 10 }}
            columns={recentOrdersColumns}
            dataSource={tableDataTest}
          />
        </TableWrapper>
      </Flex>
    </Flex>
  );
};

export default Dashboard;
