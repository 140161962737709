// ========== GET METHODS =========== //

export const getDatesManagementEndpoint = () => {
  return `/datesmanagement`;
};

export const getDatesManagementSkiEndpoint = () => {
  return `/datesmanagement/ski`;
};

export const getDatesManagementParaglidingEndpoint = () => {
  return `/datesmanagement/paragliding`;
};

export const getDatesManagementOtherEndpoint = () => {
  return `/datesmanagement/other`;
};

// ========== PUT METHODS ========== //
export const putDatesManagementSkiEndpoint = (id: string) => {
  return `/datesmanagement/ski/${id}`;
};

export const putDatesManagementParaglidingEndpoint = (id: string) => {
  return `/datesmanagement/paragliding/${id}`;
};

export const putDatesManagementOtherEndpoint = (id: string) => {
  return `/datesmanagement/other/${id}`;
};
