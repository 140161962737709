// ========== GET ENDPOINTS ========== //

export const getGudauriPageEndpoint = () => {
  return `/gudauriPage`;
};

// ========== POST ENDPOINTS ========== //

// Banner
export const postGudauriPageBannerImageEndpoint = () => {
  return `/gudauriPage/banner/images/add`;
};

// Carousel image
export const postGudauriPageCarouselImageEndpoint = () => {
  return `/gudauriPage/carouselImage`;
};

// ========== PUT ENDPOINTS ========== //

// Banner
export const putGudauriPageBannerEndpoint = (imgId?: string) => {
  let queryString: string = "";
  if (imgId) queryString = `?imgId=${imgId}`;

  return `/gudauriPage/banner${queryString}`;
};

// Wonderland section
export const putGudauriPageWonderlandSectionEndpoint = (id: string) => {
  return `/gudauriPage/wonderland/${id}`;
};

// Plan trip section
export const putGudauriPagePlanTripSectionEndpoint = (id: string) => {
  return `/gudauriPage/plantrip/${id}`;
};

// Why gudauri section
export const putGudauriPageWhyGudauriSectionEndpoint = (id: string) => {
  return `/gudauriPage/whygudaurisection/${id}`;
};

// Spirit Section
export const putGudauriPageSpiritSectionEndpoint = (id: string) => {
  return `/gudauriPage/spiritSection/${id}`;
};

// How to get section
export const putGudauriPageHowToGetSectionEndpoint = (id: string) => {
  return `/gudauriPage/howtoget/${id}`;
};

// Carousel image
export const putGudauriPageCarouselImageEndpoint = (id: string) => {
  return `/gudauriPage/carouselImage/${id}`;
};

// ========== DELETE ENDPOINTS ========== //

// Banner
export const deleteGudauriPageBannerImageEndpoint = (id: string) => {
  return `/gudauriPage/banner/images/${id}`;
};

// Carousel image
export const deleteGudauriPageCarouselImagesEndpoint = (id: string) => {
  return `/gudauriPage/carouselImage/${id}`;
};
