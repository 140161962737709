// ========== GET ENDPOINTS ========== //
export const getParaglidingPageEndpoint = () => {
  return `/paraglidingPage`;
};

// ========== POST ENDPOINTS ========== //

// Banner
export const postParaglidingPageBannerImageEndpoint = () => {
  return `/paraglidingPage/banner/images/add`;
};

// Carousel image

export const postParaglidingPageCarouselImageEndpoint = () => {
  return `/paraglidingPage/carouselImages`;
};

// ========== PUT ENDPOINTS ========== //

// Banner
export const putParaglidingPageBannerEndpoint = (imgId?: string) => {
  let queryString: string = "";
  if (imgId) queryString = `?imgId=${imgId}`;

  return `/paraglidingPage/banner${queryString}`;
};

// Main Section
export const putParaglidingPageMainSectionEndpoint = (id: string) => {
  return `/paraglidingPage/mainSection/${id}`;
};

// Form Section
export const putParaglidingPageFormSectionEndpoint = (itemId?: string) => {
  let queryString: string = "";
  if (itemId) queryString = `?itemId=${itemId}`;

  return `/paraglidingPage/formSection${queryString}`;
};

// Carousel image
export const putParaglidingPageCarouselImageEndpoint = (id: string) => {
  return `/paraglidingPage/carouselImages/${id}`;
};

// ========== DELETE ENDPOINTS ========== //

// Banner
export const deleteParaglidingPageBannerImageEndpoint = (id: string) => {
  return `/paraglidingPage/banner/images/${id}`;
};

// Carousel image
export const deleteParaglidingPageCarouselImageEndpoint = (id: string) => {
  return `/paraglidingPage/carouselImages/${id}`;
};
