import { useEffect, useState } from "react";
import HomeContent from "../../components/ContentManagement/HomeContent/HomeContent";
import { useApiRequest } from "../../hooks/useApiRequests";
import { getHomePageData } from "../../api/services/homePageServices";
import { GetHomePageResT } from "../../api/responses/homePageResponses";
import { HomeContentProps } from "../../types/component-types/homepageProps";

export const HomeContentContainer = () => {
  const [data, setData] = useState<GetHomePageResT>();
  const [, makeGetHomePageData] = useApiRequest<GetHomePageResT>();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    makeGetHomePageData(getHomePageData(), (data) => {
      setData(data);
    });
  };

  const props: HomeContentProps = {
    data,
    getData,
  };

  return <HomeContent {...props} />;
};
