import { Button, Flex } from "antd";
import classes from "./mediaCard.module.css";
import { ReactNode } from "react";

type MediaCardProps = {
  data: {
    _id: string;
    image?: {
      public_id: string;
      url: string;
    };
    title?: string;
    subtitle?: string | ReactNode;
    description?: string;
    warning?: string;
  };
  onEdit: (id: string) => void;
  onDelete?: (id: string) => void;
};

const MediaCard: React.FC<MediaCardProps> = ({ data, onEdit, onDelete }) => {
  const { _id, image, title, subtitle, description, warning } = data;
  return (
    <Flex vertical gap={12} className={classes.mediaCard}>
      <div className={classes.imgContainer}>
        {image ? (
          <img src={image?.url} alt="img" className={classes.img} />
        ) : null}
      </div>

      <Flex
        className={classes.textContent}
        vertical
        justify="space-between"
        gap={24}
      >
        <Flex vertical gap={8}>
          {title && (
            <h4>
              <span className={classes.textIndicator}>Title: </span> {title}
            </h4>
          )}
          {subtitle && (
            <h4>
              <span className={classes.textIndicator}>Subtitle: </span>
              {subtitle}
            </h4>
          )}
          {description && (
            <h4>
              <span className={classes.textIndicator}>Description: </span>
              {description}
            </h4>
          )}
          {warning && (
            <h4>
              <span className={classes.textIndicator}>Warning: </span>
              {warning}
            </h4>
          )}
        </Flex>

        <Flex justify="flex-end" gap={4}>
          <Button className={classes.btn} onClick={() => onEdit(_id)}>
            Edit
          </Button>
          {onDelete ? (
            <Button
              type="primary"
              danger
              color="red"
              className={classes.btn}
              onClick={() => onDelete(_id)}
            >
              Delete
            </Button>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MediaCard;
