import { useApiRequest } from "../../../../hooks/useApiRequests";
import useMessages from "../../../../hooks/useMessages";
import { cloudinaryUpload } from "../../../../lib/cloudinaryUpload";
import { updateParaglidingMainSection } from "../../../../api/services";
import { ParaglidingPageMainSectionProps } from "../../../../types/component-types";
import { MediaDataT } from "../../../../types/mediaType";
import React, { useState } from "react";
import { messageResT } from "../../../../api/responses";
import MediaCard from "../../../../components/MediaCard/MediaCard";
import MediaEditModal from "../../../../components/MediaEditModal/MediaEditModal";
import { Flex } from "antd";

const MainSection: React.FC<ParaglidingPageMainSectionProps> = ({
  data,
  getData,
}) => {
  const [editAboutDetail, setEditAboutDetail] = useState<MediaDataT>(data);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [, makeUpdateRequest] = useApiRequest<messageResT>();
  const { error, success, contextHolder } = useMessages();

  const showEditModal = () => {
    setIsEditModalOpen(true);
    setEditAboutDetail(data);
  };

  const handleOkEdit = () => {
    if (editAboutDetail) {
      try {
        makeUpdateRequest(
          updateParaglidingMainSection(
            {
              title: editAboutDetail.title,
              subtitle: editAboutDetail.subtitle,
              image: editAboutDetail.image,
            },
            editAboutDetail._id
          ),
          (e) => {
            setIsEditModalOpen(false);
            success(e.message);
            getData();
          }
        );
      } catch (err) {
        error("Something went wrong");
      }
    }
  };

  const handleCancelEdit = () => {
    setIsEditModalOpen(false);
  };

  const handleFieldChange = async (fieldName: string, value: string) => {
    let image: any;
    setLoading(true);
    if (value && fieldName === "image") {
      image = await cloudinaryUpload(value);
    }
    setEditAboutDetail((prev) => ({
      ...prev,
      [fieldName]: fieldName === "image" ? image : value,
    }));
    setLoading(false);
  };

  const paragraphs = data.subtitle.split("\n");
  const paragraphsWithBreaks = paragraphs.map((paragraph) => (
    <>
      <h4>{paragraph}</h4>
      <br />
    </>
  ));

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={12}>
          <h2>Main Section</h2>
          <MediaCard
            data={{ ...data, subtitle: paragraphsWithBreaks }}
            onEdit={showEditModal}
          />
        </Flex>

        <MediaEditModal
          isMediaEditModalOpen={isEditModalOpen}
          handleOk={handleOkEdit}
          handleCancel={handleCancelEdit}
          handleFieldChange={handleFieldChange}
          mediaDetails={editAboutDetail}
          isLoading={loading}
          useTextAreaForSubtitleEdit
        />
      </section>
    </>
  );
};

export default MainSection;
