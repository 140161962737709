import { useState } from "react";
import { Flex } from "antd";
import { useApiRequest } from "../../../../hooks/useApiRequests";
import useMessages from "../../../../hooks/useMessages";
import { updateGroupLesson } from "../../../../api/services/skiSchoolServices";
import MediaCard from "../../../../components/MediaCard/MediaCard";
import MediaEditModal from "../../../../components/MediaEditModal/MediaEditModal";
import { GroupLessonProps } from "../../../../types/component-types/skiSchoolPageProps";

const GroupLesson: React.FC<GroupLessonProps> = ({
  groupLessonData,
  getData,
}) => {
  const { title, subtitle } = groupLessonData;
  const [editLessonDetail, setEditLessonDetail] = useState<{
    title: string;
    subtitle: string;
  }>(groupLessonData);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [, makeUpdateRequest] = useApiRequest();
  const { error, success, contextHolder } = useMessages();

  const showEditModal = () => {
    setIsEditModalOpen(true);
    setEditLessonDetail(groupLessonData);
  };

  const handleOkEdit = () => {
    if (editLessonDetail) {
      setLoading(true);
      try {
        makeUpdateRequest(
          updateGroupLesson({
            title: editLessonDetail.title,
            subtitle: editLessonDetail.subtitle,
          }),
          (e: any) => {
            setLoading(false);
            setIsEditModalOpen(false);
            success(e.message);
            getData();
          }
        );
      } catch (err) {
        setLoading(false);
        error("Something went wrong");
      }
    }
  };

  const handleCancelEdit = () => {
    setIsEditModalOpen(false);
  };

  const handleFieldChange = (fieldName: string, value: string) => {
    setEditLessonDetail((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={12}>
          <h2>Group Lessons</h2>
          <MediaCard
            data={{
              _id: "",
              title: title,
              subtitle: subtitle,
            }}
            onEdit={showEditModal}
          />
        </Flex>
        <MediaEditModal
          isMediaEditModalOpen={isEditModalOpen}
          handleOk={handleOkEdit}
          handleCancel={handleCancelEdit}
          handleFieldChange={handleFieldChange}
          mediaDetails={editLessonDetail}
          isLoading={loading}
          containsImage={false}
        />
      </section>
    </>
  );
};

export default GroupLesson;
