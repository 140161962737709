import { ColumnsType } from "antd/es/table";

export const recentOrdersColumns: ColumnsType<any> = [
  {
    title: "Name",
    dataIndex: "fullName",
    key: "fullName",
    fixed: "left",
  },
  {
    title: "Phone Number",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "Activity Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
  },
];
