import { Flex } from "antd";
import { ContactPageContentProps } from "../../../types";
import React from "react";
import BottomSliderSection from "../../../components/BottomSliderSection/BottomSliderSection";
import {
  createContactPageBanner,
  createContactPageCarouselImage,
  deleteContactPageBanner,
  deleteContactPageCarouselImage,
  updateContactPageBanner,
  updateContactPageCarouselImage,
} from "../../../api/services";
import FAQSection from "./FAQSection/FAQSection";

const ContactPageContent: React.FC<ContactPageContentProps> = ({
  data,
  getData,
}) => {
  const { banner, faqTitles, faqQuestions, faq, carouselImages } = data;

  return (
    <Flex vertical gap={80}>
      <BottomSliderSection
        title="Banner"
        bottomSliderList={banner.images}
        SectionCreateService={createContactPageBanner}
        SectionDeleteService={deleteContactPageBanner}
        SectionUpdateService={updateContactPageBanner}
        getData={getData}
        bannerWarning
      />
      <FAQSection
        faqData={faq}
        faqQuestions={faqQuestions}
        faqTitles={faqTitles}
        getData={getData}
      />
      <BottomSliderSection
        bottomSliderList={carouselImages.images}
        SectionCreateService={createContactPageCarouselImage}
        SectionDeleteService={deleteContactPageCarouselImage}
        SectionUpdateService={updateContactPageCarouselImage}
        getData={getData}
      />
    </Flex>
  );
};

export default ContactPageContent;
