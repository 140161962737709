import { PropsWithChildren } from "react";
import { ConfigProvider } from "antd";

export const TableWrapper = ({ children }: PropsWithChildren) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: "#17283c",
            headerColor: "#fff",
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
