import { MediaDataT } from "../../../../../types/mediaType";
import { MiddleSectionsProps } from "../../../../../types/component-types";
import React, { useState } from "react";
import useMessages from "../../../../../hooks/useMessages";
import { useApiRequest } from "../../../../../hooks/useApiRequests";
import { messageResT } from "../../../../../api/responses";
import { updateHowItStartedMiddleSection } from "../../../../../api/services/aboutPageServices";
import { cloudinaryUpload } from "../../../../../lib/cloudinaryUpload";
import { Flex } from "antd";
import MediaCard from "../../../../../components/MediaCard/MediaCard";
import MediaEditModal from "../../../../../components/MediaEditModal/MediaEditModal";

const HowItStartedMiddleSections: React.FC<MiddleSectionsProps> = ({
  _id,
  subtitle,
  image,
  getData,
}) => {
  const [editSection, setEditSection] = useState<Omit<MediaDataT, "title">>({
    _id,
    subtitle,
    image,
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { error, success, contextHolder } = useMessages();

  const [, makeRequest] = useApiRequest<messageResT>();

  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
  };

  const handleUpdateSection = () => {
    try {
      setLoading(true);
      makeRequest(
        updateHowItStartedMiddleSection(editSection, editSection._id),
        (e) => {
          success(e.message);
          setIsEditModalOpen(false);
          getData();
          setLoading(false);
        }
      );
    } catch (err) {
      error("Something went wrong");
    }
  };

  const handleFieldChange = async (fieldName: string, value: any) => {
    let image: any;
    setLoading(true);
    if (value && fieldName === "image") {
      image = await cloudinaryUpload(value);
    }

    setEditSection(
      (prev) =>
        ({
          ...prev,
          [fieldName]: fieldName === "image" ? image : value,
        } as MediaDataT)
    );
    setLoading(false);
  };

  const paragraphs = subtitle.split("\n");
  const paragraphsWithBreaks = paragraphs.map((paragraph) => (
    <h4>{paragraph}</h4>
  ));

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={12}>
          <MediaCard
            data={{
              _id,
              subtitle: paragraphsWithBreaks,
              image,
            }}
            onEdit={handleEditModalOpen}
          />

          <MediaEditModal
            isMediaEditModalOpen={isEditModalOpen}
            handleOk={handleUpdateSection}
            handleCancel={() => setIsEditModalOpen(false)}
            handleFieldChange={handleFieldChange}
            mediaDetails={editSection}
            isLoading={loading}
            useTextAreaForSubtitleEdit
          />
        </Flex>
      </section>
    </>
  );
};

export default HowItStartedMiddleSections;
