import React, { useState } from "react";
import { FormProps } from "../../../../types/component-types/otherActivitiesProps";
import { Flex } from "antd";
import { useApiRequest } from "../../../../hooks/useApiRequests";
import useMessages from "../../../../hooks/useMessages";
import MediaCard from "../../../MediaCard/MediaCard";
import MediaEditModal from "../../../MediaEditModal/MediaEditModal";

const FormSection: React.FC<FormProps> = ({
  header,
  data,
  updateService,
  getData,
}) => {
  const { title, subtitle, warning } = data;
  const [editForm, setEditForm] = useState<{
    title: string;
    subtitle: string;
    warning?: string;
  }>(data);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [, makeUpdateRequest] = useApiRequest();
  const { error, success, contextHolder } = useMessages();

  const showEditModal = () => {
    setIsEditModalOpen(true);
    setEditForm(data);
  };

  const handleOkEdit = () => {
    if (editForm) {
      setLoading(true);
      try {
        makeUpdateRequest(
          updateService({
            title: editForm.title,
            subtitle: editForm.subtitle,
            warning: editForm.warning,
          }),
          (e: any) => {
            setLoading(false);
            setIsEditModalOpen(false);
            success(e.message);
            getData();
          }
        );
      } catch (err) {
        setLoading(false);
        error("Something went wrong");
      }
    }
  };

  const handleCancelEdit = () => {
    setIsEditModalOpen(false);
  };

  const handleFieldChange = (fieldName: string, value: string) => {
    setEditForm((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const paragraphs = subtitle.split("\n");
  const paragraphsWithBreaks = paragraphs.map((paragraph) => (
    <>
      <h4>{paragraph}</h4>
      <br />
    </>
  ));

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={12}>
          <h2>{header}</h2>
          <MediaCard
            data={{
              _id: "",
              title: title,
              subtitle: paragraphsWithBreaks,
              warning: warning,
            }}
            onEdit={showEditModal}
          />
        </Flex>
        <MediaEditModal
          isMediaEditModalOpen={isEditModalOpen}
          handleOk={handleOkEdit}
          handleCancel={handleCancelEdit}
          handleFieldChange={handleFieldChange}
          mediaDetails={editForm}
          isLoading={loading}
          containsImage={false}
          useTextAreaForSubtitleEdit
        />
      </section>
    </>
  );
};
export default FormSection;
