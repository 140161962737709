import { Flex, Input, Modal } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import classes from "./mediaEditModal.module.css";
import { useRef, useState } from "react";

type MediaEditModalProps = {
  isMediaEditModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  handleFieldChange: (dataIndex: string, value: any) => void;
  mediaDetails:
    | {
        title?: string;
        subtitle?: string;
        description?: string;
        warning?: string;
      }
    | undefined;
  isLoading: boolean;
  containsImage?: boolean;
  useTextAreaForSubtitleEdit?: boolean;
};

const MediaEditModal: React.FC<MediaEditModalProps> = ({
  isMediaEditModalOpen,
  handleOk,
  handleCancel,
  handleFieldChange,
  mediaDetails,
  isLoading,
  containsImage = true,
  useTextAreaForSubtitleEdit = false,
}) => {
  const [prevImage, setPrevImage] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  if (!mediaDetails) return <></>;
  const { title, subtitle, description, warning } = mediaDetails;

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      setPrevImage(URL.createObjectURL(selectedFile));
      handleFieldChange("image", selectedFile);
    }
  };

  const onOk = () => {
    handleOk();
    setPrevImage(null);
    if (inputRef.current) inputRef.current.value = "";
  };

  const onCancel = () => {
    setPrevImage(null);
    handleCancel();
  };

  return (
    <Modal
      title="Edit Details"
      open={isMediaEditModalOpen}
      onOk={onOk}
      onCancel={onCancel}
      className={classes.editModal}
      okButtonProps={{ loading: isLoading }}
      centered
    >
      <Flex vertical gap={12} className={classes.body}>
        {containsImage && (
          <Flex vertical gap={24}>
            {prevImage && (
              <img className={classes.prevImg} src={prevImage} alt="img" />
            )}
            <input type="file" ref={inputRef} onChange={handleImageChange} />
          </Flex>
        )}
        {title && (
          <div className={classes.content}>
            <p>Title: </p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("title", value);
                },
              }}
            >
              {title}
            </Paragraph>
          </div>
        )}

        <div className={classes.content}>
          <p>Subtitle: </p>
          {useTextAreaForSubtitleEdit ? (
            <Input.TextArea
              value={subtitle}
              onChange={(e) => handleFieldChange("subtitle", e.target.value)}
              autoSize={{ minRows: 3, maxRows: 15 }}
            />
          ) : (
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("subtitle", value);
                },
              }}
            >
              {subtitle}
            </Paragraph>
          )}
        </div>

        {description && (
          <div className={classes.content}>
            <p>Description: </p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("description", value);
                },
              }}
            >
              {description}
            </Paragraph>
          </div>
        )}

        {warning && (
          <div className={classes.content}>
            <p>Warning: </p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("warning", value);
                },
              }}
            >
              {warning}
            </Paragraph>
          </div>
        )}
      </Flex>
    </Modal>
  );
};

export default MediaEditModal;
