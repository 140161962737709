import axios from "axios";

export async function cloudinaryUpload(file: any) {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append(
      "upload_preset",
      `${process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET}`
    );
    const res = await axios.post(
      `${process.env.REACT_APP_CLOUDINARY_URL}`,
      formData
    );
    const url = await res.data.secure_url;
    const public_id = await res.data.public_id;

    const data = {
      url,
      public_id,
    };
    return data;
  } catch (err) {
    console.log(err);
  }
}
