import React, { useState, useRef, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { IoMdMenu } from "react-icons/io";
import MenuBar from "../MenuBar";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { useWindowDimension } from "../../hooks/useWindowDimention";
import { MdLogout } from "react-icons/md";
import classes from "./privateLayout.module.css";

const PrivateLayout = () => {
  const [menuVisible, setMenuVisible] = useState(true);
  const menuBarRef = useRef(null);
  const navigate = useNavigate();
  const { width } = useWindowDimension();

  const handleMenuVisible = () => {
    setMenuVisible(!menuVisible);
  };

  const hideMenu = () => {
    setMenuVisible(false);
  };

  useOnClickOutside(menuBarRef, hideMenu, 1024);

  useEffect(() => {
    width && width > 1024 ? setMenuVisible(true) : setMenuVisible(false);
  }, [width]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <div
      className={`${classes.layout} ${
        menuVisible ? classes.open : classes.closed
      }`}
    >
      <div ref={menuBarRef}>{menuVisible ? <MenuBar /> : null}</div>
      <div
        className={width && width <= 1024 && menuVisible ? classes.overlay : ""}
      >
        <header className={classes.header}>
          <div
            className={`${classes.headerContent} ${
              menuVisible ? classes.menuOpen : classes.menuClosed
            }`}
          >
            <IoMdMenu className={classes.icon} onClick={handleMenuVisible} />
            <MdLogout className={classes.icon} onClick={handleLogout} />
          </div>
        </header>
        <div
          className={`${classes.outlet} ${
            menuVisible ? classes.menuOpen : classes.menuClosed
          }`}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PrivateLayout;
