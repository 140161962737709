import { Button, Input, InputRef, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";

type ColumnSearchT = {
  ref: React.Ref<InputRef> | undefined;
  value: any;
  onChange: (e: any) => void;
  onPressEnter: () => void;
  onSearchClick: () => void;
  onResetClick: () => void;
};

export const ColumnSearch = ({
  ref,
  value,
  onChange,
  onPressEnter,
  onSearchClick,
  onResetClick,
}: ColumnSearchT) => {
  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        ref={ref}
        placeholder={"Search"}
        value={value}
        onChange={onChange}
        onPressEnter={onPressEnter}
        style={{ marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={onSearchClick}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={onResetClick} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  );
};
