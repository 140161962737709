import { useApiRequest } from "../../hooks/useApiRequests";
import { GetParaglidingPageResT } from "../../api/responses";
import { useEffect, useState } from "react";
import ParaglidingPageContent from "../../components/ContentManagement/ParaglidingPage/ParaglidingPageContent";
import { getParaglidingPage } from "../../api/services";

const ParaglidingContainer = () => {
  const [data, setData] = useState<GetParaglidingPageResT>();

  const [, makeRequest] = useApiRequest<GetParaglidingPageResT>();

  const getData = () => {
    makeRequest(getParaglidingPage(), (data) => {
      setData(data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  if (!data) return <p>Loading...</p>;

  return <ParaglidingPageContent data={data} getData={getData} />;
};

export default ParaglidingContainer;
