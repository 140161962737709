import { Alert } from "antd";

const BannerResolutionWarning = () => {
  return (
    <Alert
      message="Warning: Invalid Image Resolution on Banner"
      description="Please upload an image with a resolution of 1440x700 pixels only. Images with different resolutions may not display correctly."
      type="warning"
      showIcon
    />
  );
};

export default BannerResolutionWarning;
