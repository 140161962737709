import { MutableRefObject, useEffect } from "react";

type RefType<T extends HTMLElement> = MutableRefObject<T | null>;
type HandlerType = (event: MouseEvent | TouchEvent) => void;

export function useOnClickOutside<T extends HTMLElement>(
  ref: RefType<T>,
  handler: HandlerType,
  maxWidth: number
) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (window.innerWidth <= maxWidth) {
        if (!ref.current || !ref.current.contains(event.target as Node)) {
          handler(event);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [ref, handler, maxWidth]);
}
