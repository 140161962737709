import React, { useState } from "react";
import { Button, Flex, Input } from "antd";
import { useApiRequest } from "../../../../hooks/useApiRequests";
import useMessages from "../../../../hooks/useMessages";
import MediaCard from "../../../../components/MediaCard/MediaCard";
import MediaEditModal from "../../../../components/MediaEditModal/MediaEditModal";
import { updateBenefitsSection } from "../../../../api/services/skiSchoolServices";
import MediaCardsWrapper from "../../../../components/MediaCardsWrapper/MediaCardsWrapper";
import {
  BenefitSectionItemT,
  BenefitsSectionProps,
} from "../../../../types/component-types/skiSchoolPageProps";
import { CloudinaryImageT } from "../../../../types/common-types";
import { cloudinaryUpload } from "../../../../lib/cloudinaryUpload";

const BenefitsOfSkiing: React.FC<BenefitsSectionProps> = ({
  benefitsSectionData,
  getData,
}) => {
  const { title, items } = benefitsSectionData;
  const [editedTitle, setEditedTitle] = useState<string>(title);
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const [editItemDetail, setEditItemDetail] = useState<BenefitSectionItemT>();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [, makeUpdateRequest] = useApiRequest();
  const { error, success, contextHolder } = useMessages();

  const toggleTitleEdit = () => {
    if (isEditingTitle) {
      setEditedTitle(title);
    }
    setIsEditingTitle(!isEditingTitle);
  };

  const onTitleUpdateConfirm = () => {
    setIsEditingTitle(false);

    if (editedTitle !== title) {
      setLoading(true);
      makeUpdateRequest(
        updateBenefitsSection({
          sectionTitle: editedTitle,
        }),
        (e: any) => {
          setLoading(false);
          success(e.message);
          getData();
        }
      ).catch(() => {
        setLoading(false);
        error("Something went wrong");
      });
    } else {
      success("Title updated successfully");
    }
  };

  const showEditModal = (item: (typeof items)[0]) => {
    setIsEditModalOpen(true);
    setEditItemDetail({
      _id: item._id,
      subtitle: item.subtitle,
      image: item.image,
    });
  };

  const handleOkEdit = () => {
    if (editItemDetail) {
      setLoading(true);
      try {
        makeUpdateRequest(
          updateBenefitsSection(
            {
              subtitle: editItemDetail.subtitle,
              image: editItemDetail.image,
            },
            editItemDetail._id
          ),
          (e: any) => {
            setLoading(false);
            setIsEditModalOpen(false);
            success(e.message);
            getData();
          }
        );
      } catch (err) {
        setLoading(false);
        error("Something went wrong");
      }
    }
  };

  const handleCancelEdit = () => {
    setIsEditModalOpen(false);
  };

  const handleFieldChange = async (
    fieldName: string,
    value: string | CloudinaryImageT
  ) => {
    let image: any;
    setLoading(true);
    if (fieldName === "image") {
      image = await cloudinaryUpload(value);
    }

    setEditItemDetail(
      (prev) =>
        ({
          ...prev,
          [fieldName]: fieldName === "image" ? image : value,
        } as BenefitSectionItemT)
    );
    setLoading(false);
  };

  return (
    <>
      {contextHolder}

      <section>
        <Flex vertical gap={12}>
          <h2>Benefits of skiing</h2>
          <Flex gap={12}>
            <Flex align="center" gap={8}>
              <p>Title:</p>
              {isEditingTitle ? (
                <Input
                  type="text"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                />
              ) : (
                <h4>{title}</h4>
              )}
            </Flex>

            <Flex align="center" gap={12}>
              <Button onClick={toggleTitleEdit}>
                {isEditingTitle ? "Cancel" : "Edit"}
              </Button>
              {isEditingTitle && (
                <Button type="primary" onClick={onTitleUpdateConfirm}>
                  Confirm
                </Button>
              )}
            </Flex>
          </Flex>

          <MediaCardsWrapper>
            {items.map((item) => (
              <MediaCard
                key={item._id}
                data={{
                  _id: item._id,
                  image: item.image,
                  subtitle: item.subtitle,
                }}
                onEdit={() => showEditModal(item)}
              />
            ))}
          </MediaCardsWrapper>

          <MediaEditModal
            isMediaEditModalOpen={isEditModalOpen}
            handleOk={handleOkEdit}
            handleCancel={handleCancelEdit}
            handleFieldChange={handleFieldChange}
            mediaDetails={editItemDetail}
            isLoading={loading}
          />
        </Flex>
      </section>
    </>
  );
};

export default BenefitsOfSkiing;
