import { getAxiosClient } from "../../client";
import {
  deleteImageToOurStoryBannerEndpoint,
  deleteOurStoryCarouselImageEndpoint,
  getOurStoryPageEndpoint,
  postAddImageToOurStoryBannerEndpoint,
  postImageToOurStoryCarouselImageEndpoint,
  putBeginningOfParaglidingEndpoint,
  putHowItStartedMiddleSectionEndpoint,
  putHowItStartedSectionEndpoint,
  putOurStoryBannerEndpoint,
  putOurStoryCarouselImageEndpoint,
} from "../../endpoints";
import { makeApiRequest } from "../../requests";
import { ApiResponse, messageResT } from "../../responses";
import { GetOurStoryPageResT } from "../../responses/aboutPageResponses";
import { CloudinaryImageT, TitleSubtitleT } from "../../../types";

// ========== GET ========== //
export const getOurStoryPage = (): Promise<
  ApiResponse<GetOurStoryPageResT>
> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).get(
    getOurStoryPageEndpoint()
  );
  const result = makeApiRequest<GetOurStoryPageResT>(httpRequest);

  return result;
};

// ========== POST ========== //
export const addImageToOurStoryBanner = (data: {
  image: CloudinaryImageT;
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    postAddImageToOurStoryBannerEndpoint(),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const addOurStoryCarouselImage = (data: {
  images: CloudinaryImageT | CloudinaryImageT[];
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    postImageToOurStoryCarouselImageEndpoint(),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// ========== PUT ========== //
export const updateOurStoryBanner = (
  data: {
    title?: string;
    image?: CloudinaryImageT;
  },
  imgId?: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putOurStoryBannerEndpoint(imgId),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateHowItStartedSection = (
  data: {
    image: CloudinaryImageT;
  } & TitleSubtitleT,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putHowItStartedSectionEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateHowItStartedMiddleSection = (
  data: {
    image: CloudinaryImageT;
  } & Omit<TitleSubtitleT, "title">,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putHowItStartedMiddleSectionEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateBeginningOfParagliding = (
  data: {
    image: CloudinaryImageT;
  } & TitleSubtitleT,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putBeginningOfParaglidingEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateOurStoryCarouselImage = (
  data: {
    image: CloudinaryImageT;
  },
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putOurStoryCarouselImageEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// ========== DELETE ========== //
export const deleteImageToOursToryBanner = (
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).delete(
    deleteImageToOurStoryBannerEndpoint(id)
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const deleteOurStoryCarouselImage = (
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).delete(
    deleteOurStoryCarouselImageEndpoint(id)
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};
