import { AxiosPromise } from "axios";
import { ApiResponse } from "../responses/ApiResponse";

export const makeApiRequest = async <T>(
  httpPromise: AxiosPromise
): Promise<ApiResponse<T>> => {
  try {
    const response = await httpPromise;
    if (
      response.status === 200 ||
      response.status === 204 ||
      response.status === 201
    ) {
      const body = response.data as T;
      return {
        success: true,
        response: body,
        headers:
          response.headers !== undefined
            ? {
                "content-type": response.headers["contentType"],
              }
            : undefined,
      };
    } else {
      //eslint-disable-next-line
      const resp = response as any;
      const message = resp
        ? resp.data
          ? resp.data.message
          : ""
        : resp.statusText;
      return {
        success: false,
        error: {
          isAxiosError: false,
          status: response.status,
          errors: resp?.data?.errors,
          message: message,
          name: response.status.toString(),
        },
      };
    }
  } catch (err) {
    //eslint-disable-next-line
    const error = err as any;
    if (error.response) {
      const resp = error.response;
      const message = resp
        ? resp.data
          ? resp.data.message
          : ""
        : resp.statusText;
      return {
        success: false,
        error: {
          isAxiosError: false,
          status: error.response.status,
          message: message,
          errors: resp?.data?.errors,
          name: error.response.status.toString(),
        },
      };
    } else {
      return {
        success: false,
        error: {
          isAxiosError: true,
          status: 500,
          message: "ERR_CONNECTION_REFUSED. Possible network problem",
          name: "500",
        },
      };
    }
  }
};
