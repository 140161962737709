import { Flex } from "antd";
import useMessages from "../../../../../hooks/useMessages";
import { HowItStartedSectionProps } from "../../../../../types/component-types/howItStartedSectionProps";
import { MediaDataT } from "../../../../../types/mediaType";
import React, { useState } from "react";
import MediaCard from "../../../../../components/MediaCard/MediaCard";
import { useApiRequest } from "../../../../../hooks/useApiRequests";
import { messageResT } from "../../../../../api/responses";
import { cloudinaryUpload } from "../../../../../lib/cloudinaryUpload";
import { updateHowItStartedSection } from "../../../../../api/services/aboutPageServices";
import MediaEditModal from "../../../../../components/MediaEditModal/MediaEditModal";

const HowItStartedSection: React.FC<HowItStartedSectionProps> = ({
  data,
  getData,
}) => {
  const [editSection, setEditSection] = useState<MediaDataT>();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { error, success, contextHolder } = useMessages();

  const [, makeRequest] = useApiRequest<messageResT>();

  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
    setEditSection(data);
  };

  const handleUpdateSection = () => {
    try {
      if (editSection) {
        setLoading(true);
        makeRequest(
          updateHowItStartedSection(
            {
              title: editSection.title,
              subtitle: editSection.subtitle,
              image: editSection.image,
            },
            editSection._id
          ),
          (e) => {
            success(e.message);
            setIsEditModalOpen(false);
            getData();
            setLoading(false);
          }
        );
      }
    } catch (err) {
      error("Something went wrong");
    }
  };

  const handleFieldChange = async (fieldName: string, value: any) => {
    let image: any;
    setLoading(true);
    if (value && fieldName === "image") {
      image = await cloudinaryUpload(value);
    }

    setEditSection(
      (prev) =>
        ({
          ...prev,
          [fieldName]: fieldName === "image" ? image : value,
        } as MediaDataT)
    );
    setLoading(false);
  };

  const paragraphs = data.subtitle.split("\n");
  const paragraphsWithBreaks = paragraphs.map((paragraph) => (
    <h4>{paragraph}</h4>
  ));

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={12}>
          <h2>How It Started Section</h2>

          <MediaCard
            data={{ ...data, subtitle: paragraphsWithBreaks }}
            onEdit={handleEditModalOpen}
          />

          <MediaEditModal
            isMediaEditModalOpen={isEditModalOpen}
            handleOk={handleUpdateSection}
            handleCancel={() => setIsEditModalOpen(false)}
            handleFieldChange={handleFieldChange}
            mediaDetails={editSection}
            isLoading={loading}
            useTextAreaForSubtitleEdit
          />
        </Flex>
      </section>
    </>
  );
};

export default HowItStartedSection;
