import { Button, Flex } from "antd";
import { SEOT } from "../../../types/common-types";
import classes from "./seoCard.module.css";

type SEOCardProps = {
  data: SEOT;
  onEdit: (id: string) => void;
};

const SEOCard: React.FC<SEOCardProps> = ({ data, onEdit }) => {
  const {
    _id,
    meta_img,
    page_title,
    meta_title,
    meta_description,
    meta_keywords,
    meta_url,
  } = data;
  return (
    <Flex vertical gap={12} className={classes.seoCard}>
      <Flex
        className={classes.textContent}
        vertical
        justify="space-between"
        gap={12}
      >
        <h4 className={classes.textIndicator}>Meta image:</h4>
        <div className={classes.imgContainer}>
          <img src={meta_img.url} alt="img" className={classes.img} />
        </div>
      </Flex>

      <Flex
        className={classes.textContent}
        vertical
        justify="space-between"
        gap={24}
      >
        <Flex vertical gap={8}>
          <h4>
            <span className={classes.textIndicator}>Page title: </span>
            {page_title}
          </h4>

          <h4>
            <span className={classes.textIndicator}>Meta title: </span>
            {meta_title}
          </h4>

          <h4>
            <span className={classes.textIndicator}>Meta description: </span>
            {meta_description}
          </h4>

          <h4>
            <span className={classes.textIndicator}>Meta keywords: </span>
            {meta_keywords}
          </h4>

          <h4>
            <span className={classes.textIndicator}>Meta url: </span>
            {meta_url}
          </h4>
        </Flex>

        <Flex justify="flex-end" gap={4}>
          <Button className={classes.btn} onClick={() => onEdit(_id)}>
            Edit
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SEOCard;
