import { getPriceManagement } from "../../api/services/priceManagementServices";
import { GetPriceManagementResT } from "../../api/responses/priceManagementResponses";
import { useApiRequest } from "../../hooks/useApiRequests";
import { useEffect, useState } from "react";
import { Skeleton } from "antd";
import PriceDateManagementContent from "../../components/PirceDateManagementContent/PriceDateManagementContent";
import { GetDatesManagementResT } from "../../api/responses";
import { getDatesManagement } from "../../api/services";

const PriceDateManagementContainer = () => {
  const [pricesData, setPricesDataData] = useState<GetPriceManagementResT>();
  const [datesData, setDatesData] = useState<GetDatesManagementResT>();

  const [, makeGetRequest] = useApiRequest<GetPriceManagementResT>();
  const [, makeDatesRequest] = useApiRequest<GetDatesManagementResT>();

  const getData = () => {
    makeGetRequest(getPriceManagement(), (e) => {
      setPricesDataData(e);
    });
    makeDatesRequest(getDatesManagement(), (e) => {
      setDatesData(e);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  if (!pricesData || !datesData) return <Skeleton active />;

  return (
    <PriceDateManagementContent
      pricesData={pricesData}
      datesData={datesData}
      getData={getData}
    />
  );
};

export default PriceDateManagementContainer;
