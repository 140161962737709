import { OurStoryContentProps } from "../../../../types/component-types";
import { Flex } from "antd";
import React from "react";
import OurStoryBanner from "./BannerSection/OurStoryBanner";
import HowItStartedSection from "./HowItStartedSection/HowItStartedSection";
import HowItStartedMiddleSections from "./HowItStartedSection/HowItStartedMiddleSections";
import BeginningOfParaglidingSection from "./BeginningOfParaglidingSection/BeginningOfParaglidingSection";
import BottomSliderSection from "../../../../components/BottomSliderSection/BottomSliderSection";
import {
  addOurStoryCarouselImage,
  deleteOurStoryCarouselImage,
  updateOurStoryCarouselImage,
} from "../../../../api/services/aboutPageServices";

const OurStoryContent: React.FC<OurStoryContentProps> = ({ data, getData }) => {
  const {
    banner,
    howStartedSection,
    middleSections,
    beginningOfParaglidingSection,
    carouselImages,
  } = data;

  return (
    <Flex vertical gap={80}>
      <OurStoryBanner banner={banner} getData={getData} />
      <HowItStartedSection data={howStartedSection} getData={getData} />
      {middleSections.map((e) => (
        <HowItStartedMiddleSections {...e} getData={getData} key={e._id} />
      ))}
      <BeginningOfParaglidingSection
        data={beginningOfParaglidingSection}
        getData={getData}
      />
      <BottomSliderSection
        bottomSliderList={carouselImages.images}
        getData={getData}
        SectionUpdateService={updateOurStoryCarouselImage}
        SectionDeleteService={deleteOurStoryCarouselImage}
        SectionCreateService={addOurStoryCarouselImage}
      />
    </Flex>
  );
};

export default OurStoryContent;
