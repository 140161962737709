import { getAxiosClient } from "../client";
import {
  getNotificationsEndpoint,
  postContactNotificationsEndpoint,
  postOtherActivitiesNotificationsEndpoint,
  postParaglidingNotificationsEndpoint,
  postSkiNotificationsEndpoint,
} from "../endpoints";
import { makeApiRequest } from "../requests";
import { ApiResponse, messageResT } from "../responses";
import { GetNotificationsResT } from "../responses";

export const getNotifications = (): Promise<
  ApiResponse<GetNotificationsResT>
> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).get(
    getNotificationsEndpoint()
  );
  const result = makeApiRequest<GetNotificationsResT>(httpRequest);

  return result;
};

export const updateSkiNotification = (): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    postSkiNotificationsEndpoint()
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateParaglidingNotification = (): Promise<
  ApiResponse<messageResT>
> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    postParaglidingNotificationsEndpoint()
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateOtherActivitiesNotification = (): Promise<
  ApiResponse<messageResT>
> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    postOtherActivitiesNotificationsEndpoint()
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateContactNotification = (): Promise<
  ApiResponse<messageResT>
> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");
  const httpRequest = getAxiosClient(token, undefined).post(
    postContactNotificationsEndpoint()
  );
  const result = makeApiRequest<messageResT>(httpRequest);
  return result;
};
