import { useEffect, useState } from "react";
import { GetOtherActivitiesPageResT } from "../../api/responses/otherActivitiesResponses";
import { useApiRequest } from "../../hooks/useApiRequests";
import { getOtherActivitiesPageData } from "../../api/services/otherActivitiesServices";
import { OtherActivitiesContentProps } from "../../types/component-types/otherActivitiesProps";
import OtherActivitiesContent from "../../components/ContentManagement/OtherActivitiesContent/OtherActivitiesContent";

const OtherActivitiesContainer = () => {
  const [data, setData] = useState<GetOtherActivitiesPageResT>();
  const [, makeGetOtherActivitiesPageData] =
    useApiRequest<GetOtherActivitiesPageResT>();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    makeGetOtherActivitiesPageData(getOtherActivitiesPageData(), (data) => {
      setData(data);
    });
  };

  const props: OtherActivitiesContentProps = {
    data,
    getData,
  };
  return <OtherActivitiesContent {...props} />;
};

export default OtherActivitiesContainer;
