import { getAxiosClient } from "../client";
import {
  addItemToBannerOtherActivitiesEndpoint,
  addItemToBottomCarouselEndpoint,
  deleteBannerOtherACtivitiesItemEndpoint,
  deleteBottomCarouselItemEndpoint,
  getOtherActivitiesPageEndpoint,
  updateBannerOtherActivitiesEndpoint,
  updateHorseRidingFormEndpoint,
  updateMainSectionEndpoint,
  updateOtherActivitiesBottomCarouselEndpoint,
  updateQuadBikeFormEndpoint,
  updateSnowMobileFormEndpoint,
  updateTransfersFormEndpoint,
} from "../endpoints/otherActivities";
import { makeApiRequest } from "../requests";
import { ApiResponse, messageResT } from "../responses";
import { GetOtherActivitiesPageResT } from "../responses/otherActivitiesResponses";
import { MultipleImagesT } from "../../types/common-types";

// ========== GET ========== //
export const getOtherActivitiesPageData = (): Promise<
  ApiResponse<GetOtherActivitiesPageResT>
> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).get(
    getOtherActivitiesPageEndpoint()
  );
  const result = makeApiRequest<GetOtherActivitiesPageResT>(httpRequest);

  return result;
};

// ========== POST ========== //
export const addItemToBannerOtherActivities = (data: {
  image: {
    public_id: string;
    url: string;
  };
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    addItemToBannerOtherActivitiesEndpoint(),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const addItemToBottomCarouselSection = (data: {
  images: Omit<MultipleImagesT, "_id">;
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    addItemToBottomCarouselEndpoint(),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// ========== PUT =========== //

export const updateBannerOtherActivitiesSection = (
  data: {
    title?: string;
    subtitle?: string;
    image?: {
      public_id: string;
      url: string;
    };
  },
  imgId?: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateBannerOtherActivitiesEndpoint(imgId),
    data
  );

  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateMainSection = (
  data: {
    title?: string;
    subtitle?: string;
    image?: {
      public_id: string;
      url: string;
    };
  },
  imgId?: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateMainSectionEndpoint(imgId),
    data
  );

  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateTransfersForm = (data: {
  title?: string;
  subtitle?: string;
  description?: string;
  image?: {
    public_id: string;
    url: string;
  };
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateTransfersFormEndpoint(),
    data
  );

  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateSnowMobileForm = (data: {
  title?: string;
  subtitle?: string;
  warning?: string;
  description?: string;
  image?: {
    public_id: string;
    url: string;
  };
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateSnowMobileFormEndpoint(),
    data
  );

  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateHorseRidingForm = (data: {
  title?: string;
  subtitle?: string;
  warning?: string;
  description?: string;
  image?: {
    public_id: string;
    url: string;
  };
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateHorseRidingFormEndpoint(),
    data
  );

  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateQuadBikeForm = (data: {
  title?: string;
  subtitle?: string;
  warning?: string;
  description?: string;
  image?: {
    public_id: string;
    url: string;
  };
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateQuadBikeFormEndpoint(),
    data
  );

  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateOtherActivitiesBottomCarouselSection = (
  data: {
    image?: {
      public_id: string;
      url: string;
    };
  },
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateOtherActivitiesBottomCarouselEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// ========== DELETE ========== //

export const deleteBannerOtherActivitiesItem = (
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).delete(
    deleteBannerOtherACtivitiesItemEndpoint(id)
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const deleteBottomCarouselItem = (
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).delete(
    deleteBottomCarouselItemEndpoint(id)
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};
