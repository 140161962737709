import { GudauriContentProps } from "../../../../types/component-types/gudauriPageProps";
import { Flex } from "antd";
import React from "react";
import GudauriBannerSection from "./BannerSection/GudauriBannerSection";
import WonderlandSection from "./WonderlandSection/WonderlandSection";
import WhyGudauriSection from "./WhyGudauriSection/WhyGudauriSection";
import SpiritSection from "./SpiritSection/SpiritSection";
import HowToGetSection from "./HowToGetSection/HowToGetSection";
import BottomSliderSection from "../../../../components/BottomSliderSection/BottomSliderSection";
import {
  createGudauriPageCarouselImage,
  deleteGudauriPageCarouselImage,
  updateGudauriPageCarouselImage,
} from "../../../../api/services/aboutPageServices/gudauriServices";
import PlanTripSection from "./PlanTripSection/PlanTripSection";

const GudauriContent: React.FC<GudauriContentProps> = ({ data, getData }) => {
  const {
    banner,
    wonderlandSection,
    whyGudauriSection,
    spiritSection,
    planTripSection,
    howToGetThereSection,
    carouselImages,
  } = data;
  return (
    <Flex vertical gap={80}>
      <GudauriBannerSection banner={banner} getData={getData} />
      <WonderlandSection data={wonderlandSection} getData={getData} />
      <PlanTripSection data={planTripSection} getData={getData} />
      {whyGudauriSection.map((e) => (
        <WhyGudauriSection {...e} key={e._id} getData={getData} />
      ))}
      <SpiritSection data={spiritSection} getData={getData} />
      <HowToGetSection data={howToGetThereSection} getData={getData} />
      <BottomSliderSection
        bottomSliderList={carouselImages.images}
        getData={getData}
        SectionUpdateService={updateGudauriPageCarouselImage}
        SectionDeleteService={deleteGudauriPageCarouselImage}
        SectionCreateService={createGudauriPageCarouselImage}
      />
    </Flex>
  );
};

export default GudauriContent;
