import { useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { ConfigProvider, Flex, Select, Tabs } from "antd";
import { HomeContentContainer } from "../../containers/home-containers/HomeContentContainer";
import { SkiSchoolContainer } from "../../containers/ski-school-container/SkiSchoolContainer";
import OurStoryPageContainer from "../../containers/about-containers/OurStoryPageContainer";
import GudauriPageContainer from "../../containers/about-containers/GudauriPageContainer";
import ParaglidingContainer from "../../containers/paragliding-container/ParaglidingContainer";
import ContactPageContainer from "../../containers/contact-container/ContactPageContainer";
import OtherActivitiesContainer from "../../containers/other-activities-container/OtherActivitiesContainer";

const list = [
  { label: "Home", key: "1", children: <HomeContentContainer /> },
  { label: "About - Our Story", key: "2", children: <OurStoryPageContainer /> },
  { label: "About - Gudauri", key: "3", children: <GudauriPageContainer /> },
  { label: "Ski School", key: "4", children: <SkiSchoolContainer /> },
  { label: "Paragliding", key: "5", children: <ParaglidingContainer /> },
  {
    label: "Other Activities",
    key: "6",
    children: <OtherActivitiesContainer />,
  },
  { label: "Contact", key: "7", children: <ContactPageContainer /> },
];

export const ContentManagement = () => {
  const isMobile = useMediaQuery("(max-width:870px)");
  const [activeKey, setActiveKey] = useState("1");

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  const handleSelectChange = (value: string) => {
    const selectedItem = list.find((item) => item.label === value);
    if (selectedItem) {
      setActiveKey(selectedItem.key);
    }
  };

  const selectedContent = list.find((item) => item.key === activeKey)?.children;

  return (
    <Flex vertical gap={32}>
      <h2>Content Management</h2>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "#1d334d",
              itemHoverColor: "#314a69",
              itemColor: "lightgray",
              horizontalMargin: "0 0 32px 0",
            },
          },
        }}
      >
        {isMobile ? (
          <Select
            value={list.find((item) => item.key === activeKey)?.label}
            onChange={handleSelectChange}
            options={list.map((item) => ({
              value: item.label,
              label: item.label,
            }))}
          />
        ) : (
          <Tabs
            onChange={handleTabChange}
            activeKey={activeKey}
            type="card"
            items={list.map((item) => ({
              label: item.label,
              key: item.key,
            }))}
          />
        )}
        <section>{selectedContent}</section>
      </ConfigProvider>
    </Flex>
  );
};
