import { useCallback } from "react";

import { ApiResponse } from "../api/responses";
import {
  useRequestState,
  requestStart,
  requestSuccess,
  requestError,
} from "../utils";

export const useApiRequest = <T>() => {
  const [requestState, setRequestState] = useRequestState<T>();

  const makeRequest = useCallback(
    async (
      request: Promise<ApiResponse<T>>,
      onSuccessCallback?: (data: T) => void
    ) => {
      setRequestState(requestStart());
      const response = await request;

      if (response.success) {
        setRequestState(
          requestSuccess<T>(response.response!, response.headers)
        );

        if (onSuccessCallback) {
          onSuccessCallback(response.response!);
        }

        return response.response;
      } else {
        setRequestState(requestError(response.error!));
      }
    },

    [setRequestState]
  );
  return [requestState, makeRequest, setRequestState] as const;
};
