import { Flex } from "antd";
import { BsCalendar4Range } from "react-icons/bs";
import classes from "./amountsCard.module.css";

type AmountsCardT = {
  title: string;
  amountUSD: string;
  amountLari: string;
  color: "green" | "orange" | "teal" | "blue";
};

export const AmountsCard = ({
  title,
  amountUSD,
  amountLari,
  color,
}: AmountsCardT) => {
  return (
    <Flex
      vertical
      align="center"
      gap={12}
      className={`${classes.card} ${classes[color]}`}
    >
      <BsCalendar4Range className={classes.icon} />
      <p className={classes.title}>{title}</p>
      <Flex
        align="center"
        justify="space-between"
        gap={24}
        className={classes.amountsDiv}
      >
        <p className={classes.amount}>{amountUSD}</p>
        <p className={classes.amount}>{amountLari}</p>
      </Flex>
    </Flex>
  );
};
