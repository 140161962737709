import useMessages from "../../../../../hooks/useMessages";
import { MediaDataT } from "../../../../../types/mediaType";
import React, { useState } from "react";
import { updateGudauriPageWhyGudauriSection } from "../../../../../api/services/aboutPageServices/gudauriServices";
import { useApiRequest } from "../../../../../hooks/useApiRequests";
import { messageResT } from "../../../../../api/responses";
import { cloudinaryUpload } from "../../../../../lib/cloudinaryUpload";
import MediaCard from "../../../../../components/MediaCard/MediaCard";
import MediaEditModal from "../../../../../components/MediaEditModal/MediaEditModal";
import { Flex } from "antd";

const WhyGudauriSection: React.FC<{ getData: () => void } & MediaDataT> = ({
  _id,
  title,
  subtitle,
  image,
  getData,
}) => {
  const [editSection, setEditSection] = useState<MediaDataT>();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { error, success, contextHolder } = useMessages();

  const [, makeRequest] = useApiRequest<messageResT>();

  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
    setEditSection({ title, subtitle, image, _id });
  };

  const handleUpdateSection = () => {
    try {
      setLoading(true);
      if (editSection) {
        makeRequest(
          updateGudauriPageWhyGudauriSection(editSection, editSection._id),
          (e) => {
            success(e.message);
            setIsEditModalOpen(false);
            getData();
            setLoading(false);
          }
        );
      }
    } catch (err) {
      error("Something went wrong");
    }
  };

  const handleFieldChange = async (fieldName: string, value: any) => {
    let image: any;
    setLoading(true);
    if (value && fieldName === "image") {
      image = await cloudinaryUpload(value);
    }

    setEditSection(
      (prev) =>
        ({
          ...prev,
          [fieldName]: fieldName === "image" ? image : value,
        } as MediaDataT)
    );
    setLoading(false);
  };

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={12}>
          <MediaCard
            data={{
              _id,
              title,
              subtitle,
              image,
            }}
            onEdit={handleEditModalOpen}
          />

          <MediaEditModal
            isMediaEditModalOpen={isEditModalOpen}
            handleOk={handleUpdateSection}
            handleCancel={() => setIsEditModalOpen(false)}
            handleFieldChange={handleFieldChange}
            mediaDetails={editSection}
            isLoading={loading}
          />
        </Flex>
      </section>
    </>
  );
};

export default WhyGudauriSection;
