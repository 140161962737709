import { useApiRequest } from "../../hooks/useApiRequests";
import { GetContactPageResT } from "../../api/responses/contactPageResponses";
import React, { useEffect, useState } from "react";
import { getContactPage } from "../../api/services";
import ContactPageContent from "../../components/ContentManagement/ContactPage/ContactPageContent";

const ContactPageContainer = () => {
  const [data, setData] = useState<GetContactPageResT>();

  const [, makeRequest] = useApiRequest<GetContactPageResT>();

  const getData = () => {
    makeRequest(getContactPage(), (e) => {
      setData(e);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  if (!data) return <p>Loading...</p>;

  return <ContactPageContent data={data} getData={getData} />;
};

export default ContactPageContainer;
