// ========== GET REQUEST ENDPOINTS ========== //

export const getOtherActivitiesPageEndpoint = () => {
  return `/otherActivitiesPage`;
};

// ========== POST REQUEST ENDPOINTS ========== //

export const addItemToBannerOtherActivitiesEndpoint = () => {
  return `/otherActivitiesPage/banner/images/add`;
};

export const addItemToBottomCarouselEndpoint = () => {
  return `/otherActivitiesPage/carouselImage`;
};

// ========== PUT REQUEST ENDPOINTS ========== //

export const updateBannerOtherActivitiesEndpoint = (imgId?: string) => {
  let queryString: string = "";
  if (imgId) queryString = `?imgId=${imgId}`;
  return `/otherActivitiesPage/banner${queryString}`;
};

export const updateMainSectionEndpoint = (imgId?: string) => {
  let queryString: string = "";
  if (imgId) queryString = `?imgId=${imgId}`;
  return `/otherActivitiesPage/mainSection${queryString}`;
};

export const updateTransfersFormEndpoint = () => {
  return `/otherActivitiesPage/transfersForm`;
};

export const updateSnowMobileFormEndpoint = () => {
  return `/otherActivitiesPage/snowmobileForm`;
};

export const updateHorseRidingFormEndpoint = () => {
  return `/otherActivitiesPage/horseRidingForm`;
};

export const updateQuadBikeFormEndpoint = () => {
  return `/otherActivitiesPage/quadBikeForm`;
};

export const updateOtherActivitiesBottomCarouselEndpoint = (id: string) => {
  return `/otherActivitiesPage/carouselImage/${id}`;
};

// ========== DELETE REQUEST ENDPOINTS ========== //

export const deleteBannerOtherACtivitiesItemEndpoint = (id: string) => {
  return `/otherActivitiesPage/banner/images/${id}`;
};

export const deleteBottomCarouselItemEndpoint = (id: string) => {
  return `/otherActivitiesPage/carouselImage/${id}`;
};
