import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import classes from "./deleteModal.module.css";

export const deleteModal = (
  handleDeleteOrder: (state: string) => void,
  customTitle?: string
) => {
  const { confirm } = Modal;
  confirm({
    title: customTitle ? customTitle : "Are you sure to delete this order?",
    icon: <ExclamationCircleFilled />,
    className: classes.deleteModal,
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      handleDeleteOrder("");
    },
    onCancel() {},
    centered: true,
  });
};
