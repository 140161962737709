import { Flex, Modal, Select } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { OtherActivitiesT } from "../OtherActivities";
import classes from "./EditModal.module.css";

type EditModalT = {
  isEditModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  handleFieldChange: (dataIndex: string, value: any) => void;
  orderDetails: OtherActivitiesT;
};

export const EditModal = ({
  isEditModalOpen,
  handleOk,
  handleCancel,
  handleFieldChange,
  orderDetails,
}: EditModalT) => {
  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Modal
      title="Edit Order"
      open={isEditModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      className={classes.editModal}
      centered
    >
      <Flex vertical gap={12} className={classes.body}>
        <Flex gap={16} align="center">
          <p>Activity Type: </p>
          <Select
            showSearch
            placeholder={orderDetails.type}
            popupMatchSelectWidth={false}
            optionFilterProp="children"
            onChange={(value) => {
              handleFieldChange("type", value);
            }}
            onSearch={onSearch}
            filterOption={filterOption}
            listHeight={150}
            options={[
              {
                value: "Horse Riding",
                label: "Horse Riding",
              },
              {
                value: "Snow Mobile",
                label: "Snow Mobile",
              },
              {
                value: "Transfers and tours",
                label: "Transfers and tours",
              },
              {
                value: "Quad Bike",
                label: "Quad Bike",
              },
            ]}
          />
        </Flex>

        <Flex gap={16} align="center">
          <p>Full Name: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("fullName", value);
              },
            }}
          >
            {orderDetails.fullName}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Number: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("number", value);
              },
            }}
          >
            {orderDetails.number}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Email: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("email", value);
              },
            }}
          >
            {orderDetails.email}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Amount of Participants: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("participants", value);
              },
            }}
          >
            {orderDetails.participants}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Date: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("date", value);
              },
            }}
          >
            {orderDetails.date.split("T")[0]}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Time: </p>
          <Select
            showSearch
            placeholder={orderDetails.time}
            popupMatchSelectWidth={false}
            optionFilterProp="children"
            onChange={(value) => {
              handleFieldChange("time", value);
            }}
            onSearch={onSearch}
            filterOption={filterOption}
            listHeight={150}
            options={[
              {
                value: "10 AM",
                label: "10 AM",
              },
              {
                value: "11 AM",
                label: "11 AM",
              },
              {
                value: "12 AM",
                label: "12 AM",
              },
              {
                value: "1 PM",
                label: "1 PM",
              },
              {
                value: "2 PM",
                label: "2 PM",
              },
              {
                value: "3 PM",
                label: "3 PM",
              },
              {
                value: "4 PM",
                label: "4 PM",
              },
              {
                value: "5 PM",
                label: "5 PM",
              },
            ]}
          />
        </Flex>

        <Flex gap={16} align="center">
          <p>Selector: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("selector", value);
              },
            }}
          >
            {orderDetails.selector}
          </Paragraph>
        </Flex>

        {/* <Flex gap={16} align="center">
          <p>Currency: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("currency", value);
              },
            }}
          >
            {orderDetails.currency}
          </Paragraph>
        </Flex> */}

        <Flex gap={16} align="center">
          <p>Additional Details: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("additionalDetails", value);
              },
            }}
          >
            {orderDetails.additionalDetails}
          </Paragraph>
        </Flex>
      </Flex>
    </Modal>
  );
};
