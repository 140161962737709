import { useState } from "react";
import { Flex } from "antd";
import { SEOT } from "../../types/common-types";
import SEOCard from "./SEOCard/SEOCard";
import SEOEditModal from "./SEOEditModal/SEOEditModal";
import useMessages from "../../hooks/useMessages";
import { useApiRequest } from "../../hooks/useApiRequests";
import { ApiResponse, messageResT } from "../../api/responses";
import { cloudinaryUpload } from "../../lib/cloudinaryUpload";

type SEOSectionProps = {
  data: SEOT;
  getData: () => void;
  updateService: (
    data: Omit<SEOT, "_id">,
    id: string
  ) => Promise<ApiResponse<messageResT>>;
};

const SEOSection: React.FC<SEOSectionProps> = ({
  data,
  getData,
  updateService,
}) => {
  const [editSection, setEditSection] = useState<SEOT>();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { error, success, contextHolder } = useMessages();

  const [, makeRequest] = useApiRequest<messageResT>();

  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
    setEditSection(data);
  };

  const handleUpdateSection = () => {
    try {
      if (editSection) {
        setLoading(true);
        makeRequest(
          updateService(
            {
              page_title: editSection.page_title,
              meta_title: editSection.meta_title,
              meta_description: editSection.meta_description,
              meta_keywords: editSection.meta_keywords,
              meta_url: editSection.meta_url,
              meta_img: editSection.meta_img,
            },
            editSection._id
          ),
          (e) => {
            success(e.message);
            setIsEditModalOpen(false);
            getData();
            setLoading(false);
          }
        );
      }
    } catch (err) {
      error("Something went wrong");
    }
  };

  const handleFieldChange = async (fieldName: string, value: any) => {
    let image: any;
    setLoading(true);
    if (value && fieldName === "meta_img") {
      image = await cloudinaryUpload(value);
    }

    setEditSection(
      (prev) =>
        ({
          ...prev,
          [fieldName]: fieldName === "meta_img" ? image : value,
        } as SEOT)
    );
    setLoading(false);
  };

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={12}>
          <SEOCard data={data} onEdit={handleEditModalOpen} />

          <SEOEditModal
            isSEOEditModalOpen={isEditModalOpen}
            handleOk={handleUpdateSection}
            handleCancel={() => setIsEditModalOpen(false)}
            handleFieldChange={handleFieldChange}
            seoDetails={editSection}
            isLoading={loading}
          />
        </Flex>
      </section>
    </>
  );
};

export default SEOSection;
