import { getAxiosClient } from "../client";
import {
  deleteSubscribedEmailEndpoint,
  getSubscribedEmailsEndpoint,
} from "../endpoints";
import { makeApiRequest } from "../requests";
import { ApiResponse, GetSubscribeResT, messageResT } from "../responses";

export const getSubscribes = (): Promise<ApiResponse<GetSubscribeResT[]>> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).get(
    getSubscribedEmailsEndpoint()
  );
  const result = makeApiRequest<GetSubscribeResT[]>(httpRequest);

  return result;
};

export const deleteSubscribe = (
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).delete(
    deleteSubscribedEmailEndpoint(id)
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};
