import { useEffect, useState } from "react";
import { GetOurStoryPageResT } from "../../api/responses/aboutPageResponses";
import { getOurStoryPage } from "../../api/services/aboutPageServices";
import OurStoryContent from "../../components/ContentManagement/AboutPage/OurStoryContent/OurStoryContent";
import { useApiRequest } from "../../hooks/useApiRequests";

const OurStoryPageContainer = () => {
  const [data, setData] = useState<GetOurStoryPageResT>();

  const [, makeRequest] = useApiRequest<GetOurStoryPageResT>();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    makeRequest(getOurStoryPage(), (data) => {
      setData(data);
    });
  };

  if (!data) return <p>Loading...</p>;

  return <OurStoryContent data={data} getData={getData} />;
};

export default OurStoryPageContainer;
