import {
  addGudauriPageBannerImage,
  deleteGudauriPageBannerImage,
  updateGudauriPageBanner,
} from "../../../../../api/services/aboutPageServices/gudauriServices";
import { CloudinaryImageT } from "../../../../../types/common-types";
import { OurStoryBannerProps } from "../../../../../types/component-types";
import React, { useRef, useState } from "react";
import { messageResT } from "../../../../../api/responses";
import { deleteModal } from "../../../../../components/DeleteModal";
import { useApiRequest } from "../../../../../hooks/useApiRequests";
import useMessages from "../../../../../hooks/useMessages";
import { cloudinaryUpload } from "../../../../../lib/cloudinaryUpload";
import AddImgModal from "../../../../../components/AddImageModal/AddImgModal";
import { Flex, Input, Button } from "antd";
import { IoIosCloudUpload } from "react-icons/io";
import classes from "./GudauriBannerSection.module.css";
import BannerResolutionWarning from "../../../../../components/BannerResolutionWarning/BannerResolutionWarning";

const GudauriBannerSection: React.FC<OurStoryBannerProps> = ({
  banner,
  getData,
}) => {
  const { images, title } = banner;
  const [loading, setLoading] = useState<boolean>(false);
  const [titleValue, setTitleValue] = useState<string>(title);
  const [isEditTitle, setIsEditTitle] = useState(false);
  const [isEditImg, setIsEditImg] = useState<string>();
  const [image, setImage] = useState<CloudinaryImageT>();
  const [prevImg, setPrevImg] = useState<string>();
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { error, success, contextHolder } = useMessages();

  const [, makeRequest] = useApiRequest<messageResT>();

  const onTitleUpdateConfirm = () => {
    try {
      setLoading(true);
      makeRequest(
        updateGudauriPageBanner({
          title: titleValue,
        }),
        (e) => {
          success(e.message);
          setIsEditTitle(false);
          getData();
          setLoading(false);
        }
      );
    } catch (e) {
      error("Something went wrong");
    }
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setLoading(true);
      const file = e.target.files[0];
      const image = await cloudinaryUpload(file);

      setPrevImg(URL.createObjectURL(file));
      setImage(image);
      setLoading(false);
    }
  };

  const onImageUpdateConfirm = (id: string) => {
    try {
      setLoading(true);
      makeRequest(
        updateGudauriPageBanner(
          {
            image,
          },
          id
        ),
        (e) => {
          success(e.message);
          setImage(undefined);
          setPrevImg(undefined);
          setIsEditImg(undefined);
          getData();
          if (inputRef.current) inputRef.current.value = "";
          setLoading(false);
        }
      );
    } catch (e) {
      error("Something went wrong");
    }
  };

  const onAddImageConfirm = () => {
    try {
      if (!image) {
        error("Please select an image");
      } else {
        setLoading(true);
        makeRequest(addGudauriPageBannerImage({ image }), (e) => {
          success(e.message);
          setImage(undefined);
          setPrevImg(undefined);
          setIsAddModalOpen(false);
          getData();
          if (inputRef.current) inputRef.current.value = "";
          setLoading(false);
        });
      }
    } catch (err) {
      error("Something went wrong");
    }
  };

  const handleDeleteImage = (id: string) => {
    try {
      setLoading(true);
      makeRequest(deleteGudauriPageBannerImage(id), (e) => {
        success(e.message);
        getData();
        setLoading(false);
      });
    } catch (e) {
      error("Something went wrong");
    }
  };

  const onDelete = (id: string) => {
    deleteModal(
      () => handleDeleteImage(id),
      "Are you sure to delete this image?"
    );
  };

  return (
    <>
      {contextHolder}
      <AddImgModal
        handleOk={onAddImageConfirm}
        handleCancel={() => setIsAddModalOpen(false)}
        handleImageChange={handleImageChange}
        isOpen={isAddModalOpen}
        prevImage={prevImg}
        isLoading={loading}
        inputRef={inputRef}
      />
      <Flex vertical gap={24}>
        <h2>Banner</h2>

        <Flex justify="space-between" align="center">
          <Flex align="center" gap={24}>
            <Flex align="center" style={{ width: "100%" }} gap={8}>
              <p>Title:</p>

              {isEditTitle ? (
                <Input
                  type="text"
                  value={titleValue}
                  onChange={(e) => setTitleValue(e.target.value)}
                />
              ) : (
                <h4>{title}</h4>
              )}
            </Flex>
            <Flex align="center" gap={12}>
              <Button
                style={{ maxWidth: 120 }}
                onClick={() => setIsEditTitle(!isEditTitle)}
              >
                {isEditTitle ? "Cancel" : "Edit"}
              </Button>
              {isEditTitle && (
                <Button type="primary" onClick={onTitleUpdateConfirm}>
                  Confirm
                </Button>
              )}
            </Flex>
          </Flex>
          <Button
            type="primary"
            style={{ maxWidth: 95 }}
            onClick={() => setIsAddModalOpen(!isAddModalOpen)}
          >
            Add image
          </Button>
        </Flex>

        <BannerResolutionWarning />

        <Flex wrap="wrap" gap={24}>
          {images.map((e) => (
            <Flex
              vertical
              gap={12}
              align="end"
              key={e._id}
              className={classes.imageContainer}
            >
              <div className={classes.imageWrapper}>
                {e._id === isEditImg && (
                  <Flex
                    justify="center"
                    align="center"
                    className={classes.uploader}
                  >
                    <Flex vertical gap={12} align="center">
                      <IoIosCloudUpload className={classes.icon} />
                      <p>Drag and drop or click here to upload image </p>
                    </Flex>
                    <input
                      type="file"
                      ref={inputRef}
                      onChange={handleImageChange}
                    />
                  </Flex>
                )}
                <img
                  src={prevImg && e._id === isEditImg ? prevImg : e.url}
                  alt={e.url}
                />
              </div>
              <Flex align="center" gap={12}>
                <Button
                  style={{ maxWidth: 120 }}
                  onClick={() =>
                    setIsEditImg((prev) => {
                      if (prev === e._id) {
                        setPrevImg(undefined);
                        return undefined;
                      }
                      return e._id;
                    })
                  }
                >
                  {e._id === isEditImg ? "Cancel" : "Edit"}
                </Button>
                {e._id !== isEditImg && (
                  <Button type="primary" danger onClick={() => onDelete(e._id)}>
                    Delete
                  </Button>
                )}
                {e._id === isEditImg && (
                  <Button
                    type="primary"
                    onClick={() => onImageUpdateConfirm(e._id)}
                    loading={loading}
                  >
                    Confirm
                  </Button>
                )}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </>
  );
};

export default GudauriBannerSection;
