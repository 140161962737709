// ========== GET REQUESTS ========== //

export const getOurStoryPageEndpoint = () => {
  return `/ourStory`;
};

// ========== POST REQUESTS ========== //

// Banner
export const postAddImageToOurStoryBannerEndpoint = () => {
  return `/ourStory/banner/images/add`;
};

// Carousel image
export const postImageToOurStoryCarouselImageEndpoint = () => {
  return `/ourStory/carouselImage`;
};

// ========== PUT REQUESTS ========== //

// Banner
export const putOurStoryBannerEndpoint = (imgId?: string) => {
  let queryString: string = "";
  if (imgId) queryString = `?imgId=${imgId}`;

  return `/ourStory/banner${queryString}`;
};

// How it started section
export const putHowItStartedSectionEndpoint = (id: string) => {
  return `/ourStory/howItStartedSection/${id}`;
};

// How it started middle section
export const putHowItStartedMiddleSectionEndpoint = (id: string) => {
  return `/ourStory/howItStartedMiddleSection/${id}`;
};

// Beginning of paragliding
export const putBeginningOfParaglidingEndpoint = (id: string) => {
  return `/ourStory/beginningOfParagliding/${id}`;
};

// Carousel Image
export const putOurStoryCarouselImageEndpoint = (id: string) => {
  return `/ourStory/carouselImage/${id}`;
};

// ========== DELETE REQUESTS ========== //

// Banner
export const deleteImageToOurStoryBannerEndpoint = (id: string) => {
  return `/ourStory/banner/images/${id}`;
};

// Carousel image
export const deleteOurStoryCarouselImageEndpoint = (id: string) => {
  return `/ourStory/carouselImage/${id}`;
};
