import { useEffect, useState } from "react";
import { SkiSchoolContentProps } from "../../types/component-types/skiSchoolPageProps";
import SkiSchoolContent from "../../components/ContentManagement/SkiSchoolContent/SkiSchoolContent";
import { useApiRequest } from "../../hooks/useApiRequests";
import { GetSkiSchoolPageResT } from "../../api/responses/skiSchoolPageResponses";
import { getSkiSchoolPageData } from "../../api/services/skiSchoolServices";

export const SkiSchoolContainer = () => {
  const [data, setData] = useState<GetSkiSchoolPageResT>();
  const [, makeGetSkiSchoolPageData] = useApiRequest<GetSkiSchoolPageResT>();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    makeGetSkiSchoolPageData(getSkiSchoolPageData(), (data) => {
      setData(data);
    });
  };

  const props: SkiSchoolContentProps = {
    data,
    getData,
  };
  return <SkiSchoolContent {...props} />;
};
