import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export const getAxiosClient = (
  token: string | undefined,
  axiosConfig?: AxiosRequestConfig | undefined
): AxiosInstance => {
  const apiUrl = process.env.REACT_APP_API_URL;

  if (!apiUrl) {
    throw new Error(
      `Provide baseUrl as argument in axiosConfig or set REACT_APP_API_BASE_URL in the environment`
    );
  }

  const axiosClient = axios.create({
    baseURL: new URL(apiUrl).toString(),
    withCredentials: false,
    ...axiosConfig,
  });

  if (!token) return axiosClient;

  axiosClient.interceptors.request.use((req) => {
    if (req && req.headers) {
      if (!req.headers.authorization) {
        req.headers.authorization = `Bearer ${token}`;
      }
    }
    return req;
  });

  return axiosClient;
};
