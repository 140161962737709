import { ReactNode } from "react";
import { Flex } from "antd";
import classes from "./NumberOfOrdersCard.module.css";

type NumberOfOrdersT = {
  icon: ReactNode;
  title: string;
  numberOfOrders: number;
};

export const NumberOfOrdersCard = ({
  icon,
  title,
  numberOfOrders,
}: NumberOfOrdersT) => {
  return (
    <div className={classes.card}>
      <Flex gap={16} align="center">
        <div className={classes.icon}>{icon}</div>
        <Flex vertical gap={8}>
          <p>{title}</p>
          <p className={classes.numberOfOrders}>{numberOfOrders}</p>
        </Flex>
      </Flex>
    </div>
  );
};
