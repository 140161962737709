import { Button, DatePicker, DatePickerProps, Flex } from "antd";
import { GetDatesManagementSkiResT, messageResT } from "../../../api/responses";
import React, { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useApiRequest } from "../../../hooks/useApiRequests";
import { updateDatesManagementSki } from "../../../api/services";
import useMessages from "../../../hooks/useMessages";
import { SkiDatesProps } from "../../../types";

const SkiDates: React.FC<SkiDatesProps> = ({
  _id,
  name,
  type,
  startDate,
  endDate,
  dayOff,
  getData,
}) => {
  const [isEditStart, setIsEditStart] = useState<boolean>(false);
  const [isEditEnd, setIsEditEnd] = useState<boolean>(false);
  const [isEditDayOff, setIsEditDayOff] = useState<boolean>(false);
  const [editStartDate, setEditStartDate] = useState<Dayjs | null>(
    dayjs(startDate)
  );
  const [editEndDate, setEditEndDate] = useState<Dayjs | null>(dayjs(endDate));
  const [editDayOff, setEditDayOff] = useState<Dayjs | null>(
    dayOff !== "" ? dayjs(dayOff) : null
  );

  const { success, error, contextHolder } = useMessages();
  const [, makeRequest] = useApiRequest<messageResT>();

  const handleStartDateChange: DatePickerProps["onChange"] = (date) => {
    setEditStartDate(date);
  };

  const handleEndDateChange: DatePickerProps["onChange"] = (date) => {
    setEditEndDate(date);
  };

  const handleDayOffChange: DatePickerProps["onChange"] = (date) => {
    setEditDayOff(date);
  };

  const handleStartDateUpdateConfirm = () => {
    try {
      makeRequest(
        updateDatesManagementSki(
          {
            startDate: editStartDate ? editStartDate.format("YYYY-MM-DD") : "",
          },
          _id
        ),
        (e) => {
          success(e.message);
          getData();
          setIsEditStart(false);
        }
      );
    } catch (err) {
      error("Something went wrong");
    }
  };

  const handleEndDateUpdateConfirm = () => {
    try {
      makeRequest(
        updateDatesManagementSki(
          {
            endDate: editEndDate ? editEndDate.format("YYYY-MM-DD") : "",
          },
          _id
        ),
        (e) => {
          success(e.message);
          getData();
        }
      );
    } catch (err) {
      error("Something went wrong");
    } finally {
      setIsEditEnd(false);
    }
  };

  const handleDayOffUpdateConfirm = () => {
    try {
      makeRequest(
        updateDatesManagementSki(
          {
            dayOff: editDayOff ? editDayOff.format("YYYY-MM-DD") : "",
          },
          _id
        ),
        (e) => {
          success(e.message);
          getData();
        }
      );
    } catch (err) {
      error("Something went wrong");
    } finally {
      setIsEditDayOff(false);
    }
  };

  const dayOffDateRenderer = () => {
    if (isEditDayOff) return;

    if (!dayOff || dayOff.trim() === "") return "Choose date";

    return dayjs(dayOff).format("DD-MM-YYYY");
  };

  return (
    <>
      {contextHolder}
      <Flex vertical gap={24}>
        <h3>
          {name.toUpperCase()} {type.toUpperCase()}
        </h3>
        <Flex gap={8} align="center">
          <p>
            Start Date: {!isEditStart && dayjs(startDate).format("DD-MM-YYYY")}
          </p>
          {isEditStart && (
            <DatePicker
              onChange={handleStartDateChange}
              format={"DD-MM-YYYY"}
              value={editStartDate}
            />
          )}
          <Flex align="center" gap={8}>
            <Button onClick={() => setIsEditStart(!isEditStart)}>
              {isEditStart ? "Cancel" : "Edit"}
            </Button>
            {isEditStart && (
              <Button type="primary" onClick={handleStartDateUpdateConfirm}>
                Confirm
              </Button>
            )}
          </Flex>
        </Flex>
        <Flex gap={8} align="center">
          <p>
            End Date:{" "}
            {!isEditEnd && endDate !== ""
              ? dayjs(endDate).format("DD-MM-YYYY")
              : "Choose Date"}
          </p>
          {isEditEnd && (
            <DatePicker
              onChange={handleEndDateChange}
              format={"DD-MM-YYYY"}
              value={editEndDate}
            />
          )}
          <Flex align="center" gap={8}>
            <Button onClick={() => setIsEditEnd(!isEditEnd)}>
              {isEditEnd ? "Cancel" : "Edit"}
            </Button>
            {isEditEnd && (
              <Button type="primary" onClick={handleEndDateUpdateConfirm}>
                Confirm
              </Button>
            )}
          </Flex>
        </Flex>
        <Flex gap={8} align="center">
          <p>Day off: {dayOffDateRenderer()}</p>
          {isEditDayOff && (
            <DatePicker
              value={editDayOff}
              onChange={handleDayOffChange}
              format={"DD-MM-YYYY"}
            />
          )}
          <Flex align="center" gap={8}>
            <Button onClick={() => setIsEditDayOff(!isEditDayOff)}>
              {isEditDayOff ? "Cancel" : "Edit"}
            </Button>
            {isEditDayOff && (
              <Button type="primary" onClick={handleDayOffUpdateConfirm}>
                Confirm
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default SkiDates;
