import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./views/Login";
import DashboardPage from "./views/Dashboard";
import SkiSchoolPage from "./views/SkiSchool";
import ParaglidingPage from "./views/Paragliding";
import OtherActivitiesPage from "./views/OtherActivities";
import ContactPage from "./views/Contact";
import PrivateLayout from "./components/PrivateLayout";
import ActivitiesSwitcherPage from "./views/ActivitiesSwitcher";
import ContentManagementPage from "./views/ContentManagement/ContentManagement";
import SEOOptimizationPage from "./views/SEOOptimization/SEOOptimization";
import PriceDateManagement from "./views/price-date-management";
import Coupon from "./views/coupon/Coupon";
import SubscribedPage from "./views/subscribed-page/SubscribedPage";

// Function to check if the user is authenticated
const isAuthenticated = () => {
  // Replace this with your actual authentication logic
  // For example, checking if a valid token exists
  return localStorage.getItem("token") !== null;
};

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  return isAuthenticated() ? children : <Navigate to="/login" />;
};

const RoutesComponent: React.FC = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/login" element={<Login />} />
    <Route element={<PrivateLayout />}>
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/skischool"
        element={
          <PrivateRoute>
            <SkiSchoolPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/paragliding"
        element={
          <PrivateRoute>
            <ParaglidingPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/other-activities"
        element={
          <PrivateRoute>
            <OtherActivitiesPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/contact"
        element={
          <PrivateRoute>
            <ContactPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/activities-management"
        element={
          <PrivateRoute>
            <ActivitiesSwitcherPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/content-management"
        element={
          <PrivateRoute>
            <ContentManagementPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/seo-optimization-management"
        element={
          <PrivateRoute>
            <SEOOptimizationPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/price-date-management"
        element={
          <PrivateRoute>
            <PriceDateManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/coupon"
        element={
          <PrivateRoute>
            <Coupon />
          </PrivateRoute>
        }
      />{" "}
      <Route
        path="/subscribed-emails"
        element={
          <PrivateRoute>
            <SubscribedPage />
          </PrivateRoute>
        }
      />
    </Route>

    {/* Other routes */}
  </Routes>
);

export default RoutesComponent;
