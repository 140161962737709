import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import "./globals.css";

const App: React.FC = () => (
  <Router>
    <div>
      <Routes />
    </div>
  </Router>
);

export default App;
