import { useRef, useState } from "react";
import { Flex, Modal } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { SEOT } from "../../../types/common-types";
import classes from "./seoModal.module.css";

type SEOEditModalProps = {
  isSEOEditModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  handleFieldChange: (dataIndex: string, value: any) => void;
  seoDetails: SEOT | undefined;
  isLoading: boolean;
};

const SEOEditModal: React.FC<SEOEditModalProps> = ({
  isSEOEditModalOpen,
  handleOk,
  handleCancel,
  handleFieldChange,
  seoDetails,
  isLoading,
}) => {
  const [prevImage, setPrevImage] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  if (!seoDetails) return <></>;

  const { page_title, meta_title, meta_description, meta_keywords, meta_url } =
    seoDetails;

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      setPrevImage(URL.createObjectURL(selectedFile));
      handleFieldChange("meta_img", selectedFile);
    }
  };

  const onOk = () => {
    handleOk();
    setPrevImage(null);
    if (inputRef.current) inputRef.current.value = "";
  };

  const onCancel = () => {
    setPrevImage(null);
    handleCancel();
  };

  return (
    <Modal
      title="Edit Details"
      open={isSEOEditModalOpen}
      onOk={onOk}
      onCancel={onCancel}
      className={classes.editModal}
      okButtonProps={{ loading: isLoading }}
      centered
    >
      <Flex vertical gap={12} className={classes.body}>
        <Flex vertical gap={24}>
          {prevImage && (
            <img className={classes.prevImg} src={prevImage} alt="img" />
          )}
          <input type="file" ref={inputRef} onChange={handleImageChange} />
        </Flex>

        <div className={classes.content}>
          <p>Page title: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("page_title", value);
              },
            }}
          >
            {page_title}
          </Paragraph>
        </div>

        <div className={classes.content}>
          <p>Meta title: </p>

          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("meta_title", value);
              },
            }}
          >
            {meta_title}
          </Paragraph>
        </div>

        <div className={classes.content}>
          <p>Meta description: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("meta_description", value);
              },
            }}
          >
            {meta_description}
          </Paragraph>
        </div>

        <div className={classes.content}>
          <p>Meta keywords: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("meta_keywords", value);
              },
            }}
          >
            {meta_keywords}
          </Paragraph>
        </div>

        <div className={classes.content}>
          <p>Meta url: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("meta_url", value);
              },
            }}
          >
            {meta_url}
          </Paragraph>
        </div>
      </Flex>
    </Modal>
  );
};

export default SEOEditModal;
