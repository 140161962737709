import { useApiRequest } from "../../../../hooks/useApiRequests";
import useMessages from "../../../../hooks/useMessages";
import { FormItemT } from "../../../../types/common-types";
import { FormSectionProps } from "../../../../types/component-types";
import { Button, Flex, Input } from "antd";
import React, { useState } from "react";
import { messageResT } from "../../../../api/responses";
import { updateParaglidingPageFormSection } from "../../../../api/services";
import FormItems from "./form-items/FormItems";

const FormSection: React.FC<FormSectionProps> = ({ data, getData }) => {
  const { title, subtitle, items } = data;
  const [titleValue, setTitleValue] = useState<string>(title);
  const [subtitleValue, setSubtitleValue] = useState<string>(subtitle);
  const [isEditTitle, setIsEditTitle] = useState<boolean>(false);
  const [isEditSubtitle, setIsEditSubtitle] = useState<boolean>(false);
  const [isItemEdit, setIsItemEdit] = useState<string>("");
  const [itemValue, setItemValue] = useState<FormItemT>();
  const [loading, setLoading] = useState<boolean>(false);

  const { error, success, contextHolder } = useMessages();

  const [, makeRequest] = useApiRequest<messageResT>();

  const handleItemEdit = (itemId: string) => {
    const item = items.find((item) => item._id === itemId);
    setItemValue(item);
    setIsItemEdit((prev) => {
      if (prev === itemId) return "";
      return itemId;
    });
  };

  const onTitleEditConfirm = () => {
    try {
      setLoading(true);
      makeRequest(
        updateParaglidingPageFormSection({
          title: titleValue,
          subtitle: subtitleValue,
        }),
        (e) => {
          success(e.message);
          setIsEditTitle(false);
          setIsEditSubtitle(false);
          getData();
          setLoading(false);
        }
      );
    } catch (err) {
      error("Something went wrong");
    }
  };

  const onItemEditConfirm = () => {
    try {
      setLoading(true);
      makeRequest(
        updateParaglidingPageFormSection(
          {
            description: itemValue?.description,
          },
          itemValue?._id
        ),
        (e) => {
          success(e.message);
          setIsItemEdit("");
          getData();
          setLoading(false);
        }
      );
    } catch (err) {
      error("Something went wrong");
    }
  };

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={12} style={{ width: "100%" }}>
          <h2>Form Section</h2>
          <Flex vertical gap={24} style={{ width: "100%" }}>
            <Flex align="center" gap={12} style={{ width: "100%" }}>
              <Flex align="center" gap={8}>
                <p>Title: </p>

                {isEditTitle ? (
                  <Input
                    value={titleValue}
                    onChange={(e) => setTitleValue(e.target.value)}
                  />
                ) : (
                  <h4>{title}</h4>
                )}
              </Flex>

              <Flex align="center" gap={8}>
                <Button onClick={() => setIsEditTitle(!isEditTitle)}>
                  {isEditTitle ? "Cancel" : "Edit"}
                </Button>
                {isEditTitle && (
                  <Button
                    type="primary"
                    onClick={onTitleEditConfirm}
                    loading={loading}
                  >
                    Confirm
                  </Button>
                )}
              </Flex>
            </Flex>

            <Flex
              vertical
              align="flex-start"
              gap={12}
              style={{ width: "100%" }}
            >
              <Flex vertical gap={8}>
                <p>Subtitle: </p>

                {isEditSubtitle ? (
                  <textarea
                    value={subtitleValue}
                    onChange={(e) => setSubtitleValue(e.target.value)}
                    rows={5}
                    cols={50}
                    style={{ outline: "none", padding: "5px 10px" }}
                  />
                ) : (
                  <h4>{subtitle}</h4>
                )}
              </Flex>

              <Flex align="center" gap={8}>
                <Button onClick={() => setIsEditSubtitle(!isEditSubtitle)}>
                  {isEditSubtitle ? "Cancel" : "Edit"}
                </Button>
                {isEditSubtitle && (
                  <Button
                    type="primary"
                    onClick={onTitleEditConfirm}
                    loading={loading}
                  >
                    Confirm
                  </Button>
                )}
              </Flex>
            </Flex>
            <Flex vertical gap={12}>
              <h2>Items: </h2>
              {items.map((e) => (
                <FormItems
                  {...e}
                  key={e._id}
                  isEdit={isItemEdit}
                  description={e.description}
                  itemValues={itemValue}
                  setItemValue={setItemValue}
                  handleSetEdit={() => handleItemEdit(e._id)}
                  onEditConfirm={onItemEditConfirm}
                  loading={loading}
                />
              ))}
            </Flex>
          </Flex>
        </Flex>
      </section>
    </>
  );
};

export default FormSection;
