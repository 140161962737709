// ========== GET REQUEST ENDPOINTS ========== //

export const getSkiSchoolPageEndpoint = () => {
  return `/skiSchoolPage`;
};

// ========== POST REQUEST ENDPOINTS ========== //

export const addItemToBannerSkiSchoolEndpoint = () => {
  return `/skiSchoolPage/banner/images/add`;
};

// ========== PUT REQUEST ENDPOINTS ========== //

export const updateBannerSkiSchoolEndpoint = (imgId?: string) => {
  let queryString: string = "";
  if (imgId) queryString = `?imgId=${imgId}`;
  return `/skiSchoolPage/banner${queryString}`;
};

export const updateAboutSectionEndpoint = (id: string) => {
  return `/skiSchoolPage/aboutSection/${id}`;
};

export const updateIndividualLessonEndpoint = () => {
  return "skiSchoolPage/individualLesson";
};

export const updateGroupLessonEndpoint = () => {
  return "skiSchoolPage/groupLesson";
};

export const updateBenefitsSectionEndpoint = (itemId?: string) => {
  let queryString: string = "";
  if (itemId) queryString = `?itemId=${itemId}`;

  return `skiSchoolPage/benefitsSection${queryString}`;
};

export const updateRentalShopSectionEndpoint = (id: string) => {
  return `/skiSchoolPage/rentalShopSection/${id}`;
};

export const updateRepairSectionEndpoint = (id: string) => {
  return `/skiSchoolPage/repairSection/${id}`;
};

export const updateTeamSectionEndpoint = (itemId?: string) => {
  let queryString: string = "";
  if (itemId) queryString = `?itemId=${itemId}`;
  return `/skiSchoolPage/teamSection${queryString}`;
};

// ========== DELETE REQUEST ENDPOINTS ========== //

export const deleteBannerSkiSchoolItemEndpoint = (id: string) => {
  return `/skiSchoolPage/banner/images/${id}`;
};
