import { getAxiosClient } from "../client";
import {
  deleteCouponEndpoint,
  getCouponsEndpoint,
  postCouponEndpoint,
  putCouponEndpoint,
} from "../endpoints";
import { makeApiRequest } from "../requests";
import { CouponRequestT } from "../requests/couponRequests";
import { ApiResponse, messageResT } from "../responses";
import { GetCouponsResT } from "../responses/couponResponses";

export const getCoupons = (): Promise<ApiResponse<GetCouponsResT[]>> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).get(
    getCouponsEndpoint()
  );
  const result = makeApiRequest<GetCouponsResT[]>(httpRequest);
  return result;
};

export const createCoupon = (
  data: CouponRequestT
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    postCouponEndpoint(),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);
  return result;
};

export const updateCoupon = (
  data: CouponRequestT,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putCouponEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);
  return result;
};

export const deleteCoupon = (id: string): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).delete(
    deleteCouponEndpoint(id)
  );
  const result = makeApiRequest<messageResT>(httpRequest);
  return result;
};
