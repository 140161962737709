import { AddImageModalProps } from "../../types/component-types";
import { Flex, Modal } from "antd";
import React from "react";
import classes from "./AddImgModal.module.css";
import { IoIosCloudUpload } from "react-icons/io";

const AddImgModal: React.FC<AddImageModalProps> = ({
  isOpen,
  handleOk,
  handleCancel,
  customTitle,
  handleImageChange,
  prevImage,
  isLoading,
  inputRef,
}) => {
  return (
    <Modal
      title={customTitle ? customTitle : "Add Image"}
      open={isOpen}
      centered
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ loading: isLoading }}
    >
      <Flex vertical align="center" gap={24} className={classes.container}>
        <Flex
          vertical
          gap={12}
          align="center"
          justify="center"
          className={classes.uploadContent}
        >
          <input type="file" ref={inputRef} onChange={handleImageChange} />
          <IoIosCloudUpload className={classes.icon} />
          <p>
            {isLoading
              ? "Loading..."
              : "Drag and drop or click here to upload image"}
          </p>
        </Flex>
        {prevImage && <img src={prevImage} alt="preview" />}
      </Flex>
    </Modal>
  );
};

export default AddImgModal;
