import { Button, Flex, Input } from "antd";
import React, { useState } from "react";
import MediaCardsWrapper from "../../../MediaCardsWrapper/MediaCardsWrapper";
import MediaCard from "../../../MediaCard/MediaCard";
import MediaEditModal from "../../../MediaEditModal/MediaEditModal";
import { MediaDataT } from "../../../../types/mediaType";
import { ActivitiesProps } from "../../../../types/component-types/homepageProps";
import { cloudinaryUpload } from "../../../../lib/cloudinaryUpload";
import { useApiRequest } from "../../../../hooks/useApiRequests";
import { updateHomepageActivitiesSection } from "../../../../api/services/homePageServices";
import useMessages from "../../../../hooks/useMessages";
import { messageResT } from "../../../../api/responses";

const ActivitiesSection: React.FC<ActivitiesProps> = ({
  title,
  items,
  getData,
}) => {
  const [editActivityDetail, setEditActivityDetail] = useState<MediaDataT>();
  const [isEditActivityModalOpen, setIsEditActivityModalOpen] =
    useState<boolean>(false);
  const [isEditTitle, setIsEditTitle] = useState<boolean>(false);
  const [titleValue, setTitleValue] = useState<string>(title);
  const [loading, setLoading] = useState<boolean>(false);

  const { error, success, contextHolder } = useMessages();

  const [, makeUpdateActivitiesSection] = useApiRequest<messageResT>();

  const showEditActivityModal = (id: string) => {
    setIsEditActivityModalOpen(true);
    const editableActivity = items.find((item) => item._id === id);
    setEditActivityDetail(editableActivity);
  };

  const updateRequestHandler = (
    isEdit: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    try {
      makeUpdateActivitiesSection(
        updateHomepageActivitiesSection(
          {
            sectionTitle: titleValue,
            itemTitle: editActivityDetail?.title,
            subtitle: editActivityDetail?.subtitle,
            image: editActivityDetail?.image,
          },
          editActivityDetail?._id
        ),
        (e) => {
          isEdit(false);
          success(e.message);
          getData();
        }
      );
    } catch (err) {
      error("Something went wrong");
    }
  };

  const handleOkEditActivity = () => {
    updateRequestHandler(setIsEditActivityModalOpen);
  };

  const handleCancelEditActivity = () => {
    setIsEditActivityModalOpen(false);
  };

  const handleActivityFieldChange = async (
    fieldName: string,
    value: string
  ) => {
    let image: any;
    setLoading(true);

    if (value && fieldName === "image") {
      image = await cloudinaryUpload(value);
    }
    setEditActivityDetail(
      (prev) =>
        ({
          ...prev,
          [fieldName]: fieldName === "image" ? image : value,
        } as MediaDataT)
    );
    setLoading(false);
  };

  const onTitleUpdateConfirm = () => {
    updateRequestHandler(setIsEditTitle);
  };

  return (
    <>
      {contextHolder}

      <section>
        <Flex vertical gap={12}>
          <h2>Our Activities Section</h2>
          <Flex align="center" gap={24}>
            <Flex
              align="center"
              style={{ maxWidth: 220, width: "100%" }}
              gap={8}
            >
              <p>Title:</p>

              {isEditTitle ? (
                <Input
                  type="text"
                  value={titleValue}
                  onChange={(e) => setTitleValue(e.target.value)}
                />
              ) : (
                <h4>{title}</h4>
              )}
            </Flex>
            <Flex align="center" gap={12}>
              <Button
                style={{ maxWidth: 120 }}
                onClick={() => setIsEditTitle(!isEditTitle)}
              >
                {isEditTitle ? "Cancel" : "Edit"}
              </Button>
              {isEditTitle && (
                <Button type="primary" onClick={onTitleUpdateConfirm}>
                  Confirm
                </Button>
              )}
            </Flex>
          </Flex>
          <MediaCardsWrapper>
            {items.map((item) => (
              <MediaCard
                key={item._id}
                data={item}
                onEdit={() => showEditActivityModal(item._id)}
              />
            ))}
          </MediaCardsWrapper>
        </Flex>

        <MediaEditModal
          isMediaEditModalOpen={isEditActivityModalOpen}
          handleOk={handleOkEditActivity}
          handleCancel={handleCancelEditActivity}
          handleFieldChange={handleActivityFieldChange}
          mediaDetails={editActivityDetail}
          isLoading={loading}
        />
      </section>
    </>
  );
};

export default ActivitiesSection;
