import { Flex } from "antd";
import BannerSection from "./BannerSection/BannerSection";
import ActivitiesSection from "./ActivitiesSection/ActivitiesSection";
import CouponSection from "./CouponSection/CouponSection";
import WelcomeFirstSection from "./WelcomeFirstSection/WelcomeFirstSection";
import WelcomeSecondSection from "./WelcomeSecondSection/WelcomeSecondSection";
import WhatsSetsUsApartSection from "./WhatsSetsUsApartSection/WhatsSetsUsApartSection";
import BottomSliderSection from "../../BottomSliderSection/BottomSliderSection";
import { HomeContentProps } from "../../../types/component-types/homepageProps";
import {
  addItemToBottomCarouselSection,
  deleteBottomCarouselItem,
  updateHomepageBottomCarouselSection,
} from "../../../api/services/homePageServices";
import PopupSection from "./pupup-section/PopupSection";

const HomeContent: React.FC<HomeContentProps> = ({ data, getData }) => {
  if (!data) return <p>Loading...</p>;

  const {
    popup,
    banner,
    ourActivities,
    discountCoupon,
    welcomeSection,
    whatSetsApart,
    wonderlandSection,
    carouselImages,
  } = data;

  return (
    <Flex vertical gap={84}>
      <PopupSection data={popup} getData={getData} />
      <BannerSection bannerList={banner} getData={getData} />
      <ActivitiesSection {...ourActivities} getData={getData} />
      <CouponSection couponData={discountCoupon} getData={getData} />
      <WelcomeFirstSection
        welcomeFirstData={welcomeSection}
        getData={getData}
      />
      <WhatsSetsUsApartSection {...whatSetsApart} getData={getData} />
      <WelcomeSecondSection
        welcomeSecondData={wonderlandSection}
        getData={getData}
      />
      <BottomSliderSection
        bottomSliderList={carouselImages.images}
        getData={getData}
        SectionUpdateService={updateHomepageBottomCarouselSection}
        SectionDeleteService={deleteBottomCarouselItem}
        SectionCreateService={addItemToBottomCarouselSection}
      />
    </Flex>
  );
};

export default HomeContent;
