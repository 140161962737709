import { Button, Flex } from "antd";
import React, { useState } from "react";
import { GetCouponsResT } from "src/api/responses/couponResponses";
import dayjs from "dayjs";
import { CouponRequestT, messageResT } from "src/api";
import CouponModal from "../coupon-modal/CouponModal";
import { useApiRequest } from "src/hooks/useApiRequests";
import { deleteCoupon, updateCoupon } from "src/api/services/couponServices";
import useMessages from "src/hooks/useMessages";
import { deleteModal } from "src/components/DeleteModal";

const CouponCard = ({
  _id,
  name,
  expire,
  skiLessonDiscount,
  groupSkiLessonDiscount,
  groupSnowboardDiscount,
  snowboardDiscount,
  snowmobileDiscount,
  paraglidingDiscount,
  transferToursDiscount,
  horseRidingDiscount,
  quadBikeDiscount,
  getData,
}: GetCouponsResT) => {
  const [editData, setEditData] = useState<CouponRequestT>();
  const [open, setOpen] = useState<boolean>(false);

  const { success, error, contextHolder } = useMessages();

  const [, makeRequest] = useApiRequest<messageResT>();

  const handleModalOpen = () => {
    setEditData({
      name,
      expire,
      skiLessonDiscount,
      groupSkiLessonDiscount,
      groupSnowboardDiscount,
      snowboardDiscount,
      snowmobileDiscount,
      paraglidingDiscount,
      transferToursDiscount,
      horseRidingDiscount,
      quadBikeDiscount,
    });
    setOpen(true);
  };

  const handleFieldChange = (dataIndex: string, value: string | number) => {
    setEditData((prev) => ({
      ...prev,
      [dataIndex]: value,
    }));
  };

  const handleCancel = () => {
    setEditData(undefined);
    setOpen(false);
  };

  const handleOk = () => {
    if (editData) {
      try {
        makeRequest(updateCoupon(editData, _id), (e) => {
          success(e.message);
          setOpen(false);
          getData();
        });
      } catch (err) {
        error("Something went wrong");
      }
    }
  };

  const handleDeleteItem = (id: string) => {
    try {
      makeRequest(deleteCoupon(id), (e) => {
        success(e.message);
        getData();
      });
    } catch (err) {
      error("Something went wrong");
    }
  };

  const onDelete = () => {
    deleteModal(
      () => handleDeleteItem(_id),
      "Are you sure you want to delete this coupon?"
    );
  };

  return (
    <>
      {contextHolder}
      <CouponModal
        open={open}
        handleCancel={handleCancel}
        handleOk={handleOk}
        handleFieldChange={handleFieldChange}
        customTitle={"Update Coupon"}
        data={editData}
      />
      <Flex align="flex-start" vertical gap={24}>
        <Flex align="center" gap={12}>
          <p>Name: </p>
          <h3>{name}</h3>
        </Flex>
        <Flex align="center" gap={12}>
          <p>Expire: </p>
          <h3>{dayjs(expire).format("DD MMMM YYYY, HH:mm")}</h3>
        </Flex>

        <Flex align="center" gap={12}>
          <p>Individual Ski Lesson: </p>
          <h3>{skiLessonDiscount}%</h3>
        </Flex>

        <Flex align="center" gap={12}>
          <p>Individual Snowboard Lesson: </p>
          <h3>{snowboardDiscount}%</h3>
        </Flex>

        <Flex align="center" gap={12}>
          <p>Group Ski Lesson: </p>
          <h3>{groupSkiLessonDiscount}%</h3>
        </Flex>

        <Flex align="center" gap={12}>
          <p>Group Snowboard Lesson: </p>
          <h3>{groupSnowboardDiscount}%</h3>
        </Flex>

        <Flex align="center" gap={12}>
          <p>Paragliding: </p>
          <h3>{paraglidingDiscount}%</h3>
        </Flex>

        <Flex align="center" gap={12}>
          <p>Transfer And Tours: </p>
          <h3>{transferToursDiscount}%</h3>
        </Flex>

        <Flex align="center" gap={12}>
          <p>Snowmobile: </p>
          <h3>{snowmobileDiscount}%</h3>
        </Flex>

        <Flex align="center" gap={12}>
          <p>Horse Riding: </p>
          <h3>{horseRidingDiscount}%</h3>
        </Flex>

        <Flex align="center" gap={12}>
          <p>Quad Bike: </p>
          <h3>{quadBikeDiscount}%</h3>
        </Flex>
        <Flex align="center" gap={8}>
          <Button onClick={handleModalOpen}> Edit</Button>
          <Button type="primary" danger onClick={onDelete}>
            Delete
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default CouponCard;
