import { ColumnsType } from "antd/es/table";
import { SkiSchoolT } from "../skiTypes/skiTypes";

export const skiSchoolColumns: ColumnsType<SkiSchoolT> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    fixed: "left",
  },
  {
    title: "Number",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Activity Type",
    dataIndex: "activityType",
    key: "activityType",
  },
  {
    title: "Lesson Type",
    dataIndex: "lessonType",
    key: "lessonType",
  },
  {
    title: "Age",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "Group Members",
    dataIndex: "groupMembers",
    key: "groupMembers",
  },
  {
    title: "Level of Experience",
    dataIndex: "experience",
    key: "experience",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "From Hour",
    dataIndex: "fromHour",
    key: "fromHour",
  },
  {
    title: "Duration",
    dataIndex: "hours",
    key: "hours",
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "Additional",
    dataIndex: "additional",
    key: "additional",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    fixed: "right",
    width: 100,
  },
];
