// ========== GET REQUEST ENDPOINTS ========== //

export const getHomePageEndpoint = () => {
  return `/homepage`;
};

// ========== POST REQUEST ENDPOINTS ========== //

export const addItemToWhatSetsApartSectionEndpoint = () => {
  return `/homepage/whatSetsApart/item`;
};

export const addItemToBottomCarouselEndpoint = () => {
  return `/homepage/carouselImage`;
};

// ========== PUT REQUEST ENDPOINTS ========== //

export const putHomePagePopupEndpoint = (id: string) => {
  return `/homepage/popup/${id}`;
};

export const updateHomepageBannerEndpoint = (id: string) => {
  return `/homepage/banner/${id}`;
};

export const updateHomepageOurActivitiesSectionEndpoint = (itemId?: string) => {
  let queryString: string = "";
  if (itemId) queryString = `?itemId=${itemId}`;

  return `/homepage/ourActivitiesSection${queryString}`;
};

export const updateDiscountCouponEndpoint = (id: string) => {
  return `/homepage/discountCoupon/${id}`;
};

export const updateHomepageWelcomeSectionEndpoint = (id: string) => {
  return `/homepage/welcomeSection/${id}`;
};

export const updateHomepageWelcomeSecondSectionEndpoint = (id: string) => {
  return `/homepage/wonderlandSection/${id}`;
};

export const updateHomepageWhatSetsApartSectionEndpoint = (itemId?: string) => {
  let queryString: string = "";
  if (itemId) queryString = `?itemId=${itemId}`;

  return `/homepage/whatSetsApart${queryString}`;
};

export const updateHomepageWonderlandSectionEndpoint = (id: string) => {
  return `/homepage/wonderlandSection/${id}`;
};

export const updateHomepageBottomCarouselEndpoint = (id: string) => {
  return `/homepage/carouselImage/${id}`;
};

// ========== DELETE REQUEST ENDPOINTS ========== //
export const deleteWhatSetsApartSectionItemEndpoint = (id: string) => {
  return `/homepage/whatSetsApart/item/${id}`;
};

export const deleteBottomCarouselItemEndpoint = (id: string) => {
  return `/homepage/carouselImage/${id}`;
};

// ========== REPEATABLE ENDPOINTS ========== //

export const homePageCarouselImageEndpoint = () => {
  return `/homepage/carouselImages`;
};
