import { getAxiosClient } from "../client";
import {
  getDatesManagementEndpoint,
  getDatesManagementOtherEndpoint,
  getDatesManagementParaglidingEndpoint,
  getDatesManagementSkiEndpoint,
  putDatesManagementOtherEndpoint,
  putDatesManagementParaglidingEndpoint,
  putDatesManagementSkiEndpoint,
} from "../endpoints";
import { makeApiRequest } from "../requests";
import {
  PutDatesManagementParaglidingReqT,
  PutDatesManagementSkiReqT,
} from "../requests";
import {
  ApiResponse,
  GetDatesManagementOtherResT,
  GetDatesManagementParaglidingResT,
  GetDatesManagementResT,
  GetDatesManagementSkiResT,
  messageResT,
} from "../responses";

// ========== GET METHODS ========== //

export const getDatesManagement = (): Promise<
  ApiResponse<GetDatesManagementResT>
> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).get(
    getDatesManagementEndpoint()
  );
  const result = makeApiRequest<GetDatesManagementResT>(httpRequest);

  return result;
};

export const getDatesManagementSki = (): Promise<
  ApiResponse<GetDatesManagementSkiResT[]>
> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).get(
    getDatesManagementSkiEndpoint()
  );
  const result = makeApiRequest<GetDatesManagementSkiResT[]>(httpRequest);

  return result;
};

export const getDatesManagementParagliding = (): Promise<
  ApiResponse<GetDatesManagementParaglidingResT>
> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).get(
    getDatesManagementParaglidingEndpoint()
  );
  const result = makeApiRequest<GetDatesManagementParaglidingResT>(httpRequest);

  return result;
};

export const getDatesManagementOther = (): Promise<
  ApiResponse<GetDatesManagementOtherResT[]>
> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).get(
    getDatesManagementOtherEndpoint()
  );
  const result = makeApiRequest<GetDatesManagementOtherResT[]>(httpRequest);

  return result;
};

// ========== PUT METHODS ========== //

export const updateDatesManagementSki = (
  data: PutDatesManagementSkiReqT,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putDatesManagementSkiEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateDatesManagementParagliding = (
  data: PutDatesManagementParaglidingReqT,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putDatesManagementParaglidingEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateDatesManagementOther = (
  data: PutDatesManagementParaglidingReqT,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putDatesManagementOtherEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};
