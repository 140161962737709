import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  MdOutlineDashboard,
  MdOutlineSnowmobile,
  MdLogout,
} from "react-icons/md";
import { LiaSkiingSolid } from "react-icons/lia";
import { PiParachuteDuotone } from "react-icons/pi";
import { FiUsers } from "react-icons/fi";
import { IoIosSwitch } from "react-icons/io";
import { TbDeviceIpadMinus } from "react-icons/tb";
import { GrOptimize } from "react-icons/gr";
import { CiSettings } from "react-icons/ci";
import classes from "./menuBar.module.css";
import { useApiRequest } from "../../hooks/useApiRequests";
import { GetNotificationsResT, messageResT } from "../../api/responses";
import {
  getNotifications,
  updateContactNotification,
  updateOtherActivitiesNotification,
  updateParaglidingNotification,
  updateSkiNotification,
} from "../../api/services";
import { Skeleton } from "antd";
import { RiCoupon3Line } from "react-icons/ri";
import { MdSubscriptions } from "react-icons/md";

const MenuBar = () => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState("");
  const [data, setData] = useState<GetNotificationsResT>();

  const [, makeGetRequest] = useApiRequest<GetNotificationsResT>();
  const [, makePostRequest] = useApiRequest<messageResT>();

  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location]);

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const getData = () => {
    makeGetRequest(getNotifications(), (e) => {
      setData(e);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  if (!data)
    return (
      <div className={classes.menuBar}>
        <div className={classes.content}>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      </div>
    );

  const {
    skiNotification: ski,
    otherActivitiesNotification: other,
    paraglidingNotification: paragliding,
    contactNotification: contact,
  } = data;

  const { skiNotification } = ski;
  const { otherActivitiesNotification } = other;
  const { paraglidingNotification } = paragliding;
  const { contactNotification } = contact;

  const handleUpdateNotification = (arg: string) => {
    if (arg === "ski")
      return makePostRequest(updateSkiNotification(), () => {
        getData();
      });

    if (arg === "other")
      return makePostRequest(updateOtherActivitiesNotification(), () => {
        getData();
      });

    if (arg === "paragliding")
      return makePostRequest(updateParaglidingNotification(), () => {
        getData();
      });

    return makePostRequest(updateContactNotification(), () => {
      getData();
    });
  };

  return (
    <div className={classes.menuBar}>
      <div className={classes.content}>
        <Link to="/dashboard">
          <img
            src={process.env.PUBLIC_URL + "/images/logo-dark.png"}
            alt="logo"
            width={50}
          />
        </Link>
        <div className={classes.links}>
          <Link
            to="/dashboard"
            className={`${classes.link} ${
              currentPage === "/dashboard" ? classes.activeLink : ""
            }`}
          >
            <MdOutlineDashboard className={classes.icon} />
            Dashboard
          </Link>
          <Link
            to="/skischool"
            className={`${classes.link} ${
              currentPage === "/skischool" ? classes.activeLink : ""
            } ${skiNotification ? classes.notified : ""}`}
            onClick={() => handleUpdateNotification("ski")}
          >
            <LiaSkiingSolid className={classes.icon} />
            Ski School
          </Link>
          <Link
            to="/paragliding"
            className={`${classes.link} ${
              currentPage === "/paragliding" ? classes.activeLink : ""
            } ${paraglidingNotification ? classes.notified : ""}`}
            onClick={() => handleUpdateNotification("paragliding")}
          >
            <PiParachuteDuotone className={classes.icon} />
            Paragliding
          </Link>
          <Link
            to="/other-activities"
            className={`${classes.link} ${
              currentPage === "/other-activities" ? classes.activeLink : ""
            } ${otherActivitiesNotification ? classes.notified : ""}`}
            onClick={() => handleUpdateNotification("other")}
          >
            <MdOutlineSnowmobile className={classes.icon} />
            Other Activities
          </Link>
          <Link
            to="/contact"
            className={`${classes.link} ${
              currentPage === "/contact" ? classes.activeLink : ""
            } ${contactNotification ? classes.notified : ""}`}
            onClick={() => handleUpdateNotification("contact")}
          >
            <FiUsers className={classes.icon} />
            Contact
          </Link>

          <Link
            to="/subscribed-emails"
            className={`${classes.link} ${
              currentPage === "/subscribed-emails" ? classes.activeLink : ""
            }`}
          >
            <MdSubscriptions className={classes.icon} />
            Subscribed Emails
          </Link>

          <Link
            to="/activities-management"
            className={`${classes.link} ${
              currentPage === "/activities-management" ? classes.activeLink : ""
            }`}
          >
            <IoIosSwitch className={classes.icon} />
            Activities Management
          </Link>
          <Link
            to="/content-management"
            className={`${classes.link} ${
              currentPage === "/content-management" ? classes.activeLink : ""
            }`}
          >
            <TbDeviceIpadMinus className={classes.icon} />
            Content Management
          </Link>
          <Link
            to="/seo-optimization-management"
            className={`${classes.link} ${
              currentPage === "/seo-optimization-management"
                ? classes.activeLink
                : ""
            }`}
          >
            <GrOptimize className={classes.icon} />
            SEO Optimization
          </Link>
          <Link
            to="/price-date-management"
            className={`${classes.link} ${
              currentPage === "/price-date-management" ? classes.activeLink : ""
            }`}
          >
            <CiSettings className={classes.icon} />
            Price/Date Management
          </Link>
          <Link
            to="/coupon"
            className={`${classes.link} ${
              currentPage === "/coupon" ? classes.activeLink : ""
            }`}
          >
            <RiCoupon3Line className={classes.icon} />
            Coupons
          </Link>
        </div>
      </div>
      <button className={classes.btn} onClick={handleLogout}>
        <MdLogout />
        Log Out
      </button>
    </div>
  );
};

export default MenuBar;
