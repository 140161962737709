import { MediaDataT } from "../../types";
import { getAxiosClient } from "../client";
import {
  addItemToWhatSetsApartSectionEndpoint,
  deleteWhatSetsApartSectionItemEndpoint,
  getHomePageEndpoint,
  updateDiscountCouponEndpoint,
  updateHomepageBannerEndpoint,
  updateHomepageOurActivitiesSectionEndpoint,
  updateHomepageWelcomeSectionEndpoint,
  updateHomepageWelcomeSecondSectionEndpoint,
  updateHomepageWhatSetsApartSectionEndpoint,
  updateHomepageBottomCarouselEndpoint,
  deleteBottomCarouselItemEndpoint,
  addItemToBottomCarouselEndpoint,
  putHomePagePopupEndpoint,
} from "../endpoints/homepage";
import { makeApiRequest } from "../requests";
import { ApiResponse, messageResT } from "../responses";
import { GetHomePageResT } from "../responses/homePageResponses";
import { MultipleImagesT } from "../../types/common-types";

// ========== GET ========== //
export const getHomePageData = (): Promise<ApiResponse<GetHomePageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).get(
    getHomePageEndpoint()
  );
  const result = makeApiRequest<GetHomePageResT>(httpRequest);

  return result;
};

// ========== POST ========== //

// What sets apart section
export const addItemToWhatSetsApartSection = (
  data: MediaDataT
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    addItemToWhatSetsApartSectionEndpoint(),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// Bottom carousel section
export const addItemToBottomCarouselSection = (data: {
  images: Omit<MultipleImagesT, "_id">;
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    addItemToBottomCarouselEndpoint(),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// ========== PUT =========== //

// Popup
export const updateHomePagePopup = (
  data: {
    title?: string;
    subtitle?: string;
    image?: {
      public_id: string;
      url: string;
    };
  },
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putHomePagePopupEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// Banner
export const updateHomePageBanner = (
  data: {
    title?: string;
    subtitle?: string;
    image?: {
      public_id: string;
      url: string;
    };
  },
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateHomepageBannerEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// Our Activities Section
export const updateHomepageActivitiesSection = (
  data: {
    sectionTitle?: string;
    itemTitle?: string;
    subtitle?: string;
    image?: {
      public_id: string;
      url: string;
    };
  },
  itemId?: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateHomepageOurActivitiesSectionEndpoint(itemId),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// Discount Coupon Section
export const updateHomepageDiscountCoupon = (
  data: {
    title?: string;
    subtitle?: string;
    imgUrl?: string;
  },
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateDiscountCouponEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// Welcome first Section
export const updateHomepageWelcomeFirstSection = (
  data: {
    title?: string;
    subtitle?: string;
    imgUrl?: string;
  },
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateHomepageWelcomeSectionEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// Welcome second Section
export const updateHomepageWelcomeSecondSection = (
  data: {
    title?: string;
    subtitle?: string;
    image?: {
      public_id: string;
      url: string;
    };
  },
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateHomepageWelcomeSecondSectionEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// What Sets Apart Section
export const updateHomepageWhatSetsApartSection = (
  data: {
    sectionTitle?: string;
    itemTitle?: string;
    subtitle?: string;
    image?: {
      public_id: string;
      url: string;
    };
  },
  itemId?: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateHomepageWhatSetsApartSectionEndpoint(itemId),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// Bottom slider Section
export const updateHomepageBottomCarouselSection = (
  data: {
    image?: {
      public_id: string;
      url: string;
    };
  },
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateHomepageBottomCarouselEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// ========== DELETE ========== //
export const deleteWhatSetsApartSectionItem = (
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).delete(
    deleteWhatSetsApartSectionItemEndpoint(id)
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const deleteBottomCarouselItem = (
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).delete(
    deleteBottomCarouselItemEndpoint(id)
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};
