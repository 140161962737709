import { Flex } from "antd";
import { ParaglidingPageContentProps } from "../../../types/component-types";
import React from "react";
import ParaglidingBanner from "./ParaglidingBanner/ParaglidingBanner";
import MainSection from "./MainSection/MainSection";
import FormSection from "./FormSection/FormSection";
import BottomSliderSection from "../../../components/BottomSliderSection/BottomSliderSection";
import {
  createParaglidingPageCarouselImage,
  deleteParaglidingPageCarouselImage,
  updateParaglidingPageCarousel,
} from "../../../api/services";

const ParaglidingPageContent: React.FC<ParaglidingPageContentProps> = ({
  data,
  getData,
}) => {
  const { banner, mainSection, formSection, carouselImages } = data;
  return (
    <Flex vertical gap={80}>
      <ParaglidingBanner bannerData={banner} getData={getData} />
      <MainSection data={mainSection} getData={getData} />
      <FormSection data={formSection} getData={getData} />
      <BottomSliderSection
        bottomSliderList={carouselImages.images}
        getData={getData}
        SectionUpdateService={updateParaglidingPageCarousel}
        SectionDeleteService={deleteParaglidingPageCarouselImage}
        SectionCreateService={createParaglidingPageCarouselImage}
      />
    </Flex>
  );
};

export default ParaglidingPageContent;
