import { useApiRequest } from "../../../hooks/useApiRequests";
import { ParaglidingReqT } from "../../../api/requests";
import useMessages from "../../../hooks/useMessages";
import { Button, Flex, Input } from "antd";
import React, { useState } from "react";
import { messageResT } from "../../../api/responses";
import { updatePriceManagementParagliding } from "../../../api/services/priceManagementServices";

const ParaglidingPriceUpdate: React.FC<
  { getData: () => void } & ParaglidingReqT
> = ({ _id, paragliding, getData }) => {
  const [editValue, setEditValue] = useState<ParaglidingReqT>({
    _id,
    paragliding,
  });
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { success, error, contextHolder } = useMessages();

  const [, makeRequest] = useApiRequest<messageResT>();

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setEditValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateConfirm = () => {
    try {
      makeRequest(
        updatePriceManagementParagliding(
          {
            paragliding: editValue.paragliding,
          },
          editValue._id
        ),
        (e) => {
          success(e.message);
          getData();
          handleEdit();
        }
      );
    } catch (err) {
      error("Something went wrong");
    }
  };

  return (
    <>
      {contextHolder}
      <Flex gap={24} vertical>
        <h2>Paragliding</h2>

        <Flex align="center" gap={12}>
          <h3>Paragliding Price: </h3>
          {isEdit ? (
            <Input
              value={editValue.paragliding}
              onChange={handleChange}
              name="paragliding"
              style={{ maxWidth: 120 }}
            />
          ) : (
            <p>{paragliding}₾</p>
          )}

          <Flex gap={8} align="center">
            <Button onClick={handleEdit}>{isEdit ? "Cancel" : "Edit"}</Button>
            {isEdit && (
              <Button type="primary" onClick={handleUpdateConfirm}>
                Confirm update
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default ParaglidingPriceUpdate;
