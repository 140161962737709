import { Flex, Skeleton } from "antd";

export const ActivitiesSkeleton = () => {
  return (
    <Flex vertical gap={36}>
      <Skeleton.Input active />
      <Skeleton.Button active />
      <Skeleton active paragraph={{ rows: 8 }} />
      <Skeleton active paragraph={{ rows: 8 }} />
    </Flex>
  );
};
