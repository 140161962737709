import { getAxiosClient } from "../client";
import {
  getPriceManagementEndpoint,
  putPriceManagementGroupSkiEndpoint,
  putPriceManagementGroupSnowboardEndpoint,
  putPriceManagementIndividualSkiEndpoint,
  putPriceManagementIndividualSnowboardEndpoint,
  putPriceManagementParaglidingEndpoint,
  putPriceManagementQuadBikeEndpoint,
  putPriceManagementSnowMobileEndpoint,
  putPriceManagementTransferEndpoint,
} from "../endpoints";
import {
  GroupLessonReqT,
  IndividualLessonReqT,
  makeApiRequest,
  ParaglidingReqT,
  QuadBikeReqT,
  SnowmobileReqT,
  TransferReqT,
} from "../requests";
import { ApiResponse, messageResT } from "../responses";
import { GetPriceManagementResT } from "../responses/priceManagementResponses";

export const getPriceManagement = (): Promise<
  ApiResponse<GetPriceManagementResT>
> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");
  const httpRequest = getAxiosClient(token, undefined).get(
    getPriceManagementEndpoint()
  );

  const result = makeApiRequest<GetPriceManagementResT>(httpRequest);

  return result;
};

export const updatePriceManagementIndividualSki = (
  data: Omit<IndividualLessonReqT, "_id">,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putPriceManagementIndividualSkiEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updatePriceManagementIndividualSnowboard = (
  data: Omit<IndividualLessonReqT, "_id">,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putPriceManagementIndividualSnowboardEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updatePriceManagementGroupSki = (
  data: Omit<GroupLessonReqT, "_id">,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putPriceManagementGroupSkiEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updatePriceManagementGroupSnowboard = (
  data: Omit<GroupLessonReqT, "_id">,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putPriceManagementGroupSnowboardEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updatePriceManagementParagliding = (
  data: Omit<ParaglidingReqT, "_id">,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putPriceManagementParaglidingEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updatePriceManagementQuadBike = (
  data: Omit<QuadBikeReqT, "_id">,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putPriceManagementQuadBikeEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updatePriceManagementSnowmobile = (
  data: Omit<SnowmobileReqT, "_id">,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putPriceManagementSnowMobileEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updatePriceManagementTransfer = (
  data: Omit<TransferReqT, "_id">,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putPriceManagementTransferEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};
