import { getAxiosClient } from "../../client";
import {
  deleteGudauriPageBannerImageEndpoint,
  deleteGudauriPageCarouselImagesEndpoint,
  getGudauriPageEndpoint,
  postGudauriPageBannerImageEndpoint,
  postGudauriPageCarouselImageEndpoint,
  putGudauriPageBannerEndpoint,
  putGudauriPageCarouselImageEndpoint,
  putGudauriPageHowToGetSectionEndpoint,
  putGudauriPagePlanTripSectionEndpoint,
  putGudauriPageSpiritSectionEndpoint,
  putGudauriPageWhyGudauriSectionEndpoint,
  putGudauriPageWonderlandSectionEndpoint,
} from "../../endpoints";
import { makeApiRequest } from "../../requests";
import { ApiResponse, messageResT } from "../../responses";
import { GetGudauriPageResT } from "../../responses/aboutPageResponses";
import {
  CloudinaryImageT,
  DescribedContentT,
  MediaDataT,
} from "../../../types";

// ========== GET ========== //
export const getGudauriPage = (): Promise<ApiResponse<GetGudauriPageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).get(
    getGudauriPageEndpoint()
  );
  const result = makeApiRequest<GetGudauriPageResT>(httpRequest);

  return result;
};

// ========== POST ========== //
export const addGudauriPageBannerImage = (data: {
  image: CloudinaryImageT;
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    postGudauriPageBannerImageEndpoint(),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const createGudauriPageCarouselImage = (data: {
  images: CloudinaryImageT | CloudinaryImageT[];
}): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).post(
    postGudauriPageCarouselImageEndpoint(),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// ========== PUT ========== //
export const updateGudauriPageBanner = (
  data: {
    title?: string;
    image?: CloudinaryImageT;
  },
  imgId?: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putGudauriPageBannerEndpoint(imgId),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateGudauriPageWonderland = (
  data: Omit<MediaDataT, "_id">,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putGudauriPageWonderlandSectionEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateGudauriPagePlantrip = (
  data: Omit<MediaDataT, "_id">,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putGudauriPagePlanTripSectionEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateGudauriPageWhyGudauriSection = (
  data: { title?: string } & Omit<MediaDataT, "title">,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putGudauriPageWhyGudauriSectionEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateGudauriPageSpiritSection = (
  data: Omit<MediaDataT, "_id">,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putGudauriPageSpiritSectionEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateGudauriHowToGetSection = (
  data: DescribedContentT,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putGudauriPageHowToGetSectionEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const updateGudauriPageCarouselImage = (
  data: {
    image: CloudinaryImageT;
  },
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    putGudauriPageCarouselImageEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

// ========== DELETE ========== //
export const deleteGudauriPageBannerImage = (
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).delete(
    deleteGudauriPageBannerImageEndpoint(id)
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};

export const deleteGudauriPageCarouselImage = (
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).delete(
    deleteGudauriPageCarouselImagesEndpoint(id)
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};
