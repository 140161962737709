import { Button, Flex } from "antd";
import { MultipleImagesT } from "../../types/common-types";
import classes from "./imageCard.module.css";

type ImageCardProps = {
  data: MultipleImagesT;
  onEdit: (id: string) => void;
  onDelete?: (id: string) => void;
};

const ImageCard: React.FC<ImageCardProps> = ({ data, onEdit, onDelete }) => {
  const { _id, url } = data;
  return (
    <Flex vertical gap={12} className={classes.imageCard}>
      <div className={classes.imgContainer}>
        <img src={url} alt="img" className={classes.img} />
      </div>

      <Flex
        className={classes.textContent}
        vertical
        justify="space-between"
        gap={24}
      >
        <Flex justify="flex-end" gap={4}>
          <Button className={classes.btn} onClick={() => onEdit(_id)}>
            Edit
          </Button>

          {onDelete ? (
            <Button
              type="primary"
              danger
              color="red"
              className={classes.btn}
              onClick={() => onDelete(_id)}
            >
              Delete
            </Button>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ImageCard;
