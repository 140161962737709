import { DatePicker, Flex, Input, Modal } from "antd";
import React from "react";
import { CouponModalProps } from "src/types";
import dayjs from "dayjs";

const CouponModal: React.FC<CouponModalProps> = ({
  handleOk,
  handleCancel,
  handleFieldChange,
  open,
  customTitle,
  data,
}) => {
  const datePickerValue = () => {
    if (data?.expire) return dayjs(data.expire);

    return null;
  };

  return (
    <Modal
      open={open}
      title={customTitle}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Flex align="flex-start" gap={48}>
        <Flex vertical gap={12}>
          <Flex vertical gap={4}>
            <p>Coupon Name</p>
            <Input
              value={data?.name}
              onChange={(e) => handleFieldChange("name", e.target.value)}
            />
          </Flex>

          <Flex vertical gap={4}>
            <p>Expire Date</p>
            <DatePicker
              value={datePickerValue()}
              onChange={(_, e) => handleFieldChange("expire", e)}
              format="YYYY-MM-DD HH:mm:ss"
              showTime
            />
          </Flex>

          <Flex vertical gap={4}>
            <p>Individual Ski Lesson</p>
            <Input
              value={data?.skiLessonDiscount}
              onChange={(e) =>
                handleFieldChange("skiLessonDiscount", e.target.value)
              }
            />
          </Flex>

          <Flex vertical gap={4}>
            <p>Individual Snowboard Lesson</p>
            <Input
              value={data?.snowboardDiscount}
              onChange={(e) =>
                handleFieldChange("snowboardDiscount", e.target.value)
              }
            />
          </Flex>

          <Flex vertical gap={4}>
            <p>Group Ski Lesson</p>
            <Input
              value={data?.groupSkiLessonDiscount}
              onChange={(e) =>
                handleFieldChange("groupSkiLessonDiscount", e.target.value)
              }
            />
          </Flex>

          <Flex vertical gap={4}>
            <p>Group Snowboard Lesson</p>
            <Input
              value={data?.groupSnowboardDiscount}
              onChange={(e) =>
                handleFieldChange("groupSnowboardDiscount", e.target.value)
              }
            />
          </Flex>
        </Flex>

        <Flex vertical gap={12}>
          <Flex vertical gap={4}>
            <p>Paragliding</p>
            <Input
              value={data?.paraglidingDiscount}
              onChange={(e) =>
                handleFieldChange("paraglidingDiscount", e.target.value)
              }
            />
          </Flex>

          <Flex vertical gap={4}>
            <p>Transfer And Tours</p>
            <Input
              value={data?.transferToursDiscount}
              onChange={(e) =>
                handleFieldChange("transferToursDiscount", e.target.value)
              }
            />
          </Flex>

          <Flex vertical gap={4}>
            <p>Snowmobile</p>
            <Input
              value={data?.snowmobileDiscount}
              onChange={(e) =>
                handleFieldChange("snowmobileDiscount", e.target.value)
              }
            />
          </Flex>

          <Flex vertical gap={4}>
            <p>Horse Riding</p>
            <Input
              value={data?.horseRidingDiscount}
              onChange={(e) =>
                handleFieldChange("horseRidingDiscount", e.target.value)
              }
            />
          </Flex>

          <Flex vertical gap={4}>
            <p>Quad Bike</p>
            <Input
              value={data?.quadBikeDiscount}
              onChange={(e) =>
                handleFieldChange("quadBikeDiscount", e.target.value)
              }
            />
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default CouponModal;
