import { ReactNode } from "react";
import { Flex, Switch } from "antd";
import classes from "./SwitcherComponent.module.css";

type SwitcherT = {
  icon: ReactNode;
  title: string;
  defaultChecked: boolean;
  onChange: (checked: boolean) => void;
};

export const SwitcherComponent = ({
  icon,
  title,
  defaultChecked,
  onChange,
}: SwitcherT) => {
  return (
    <Flex
      justify="space-between"
      align="center"
      gap={16}
      className={classes.outline}
    >
      <Flex gap={12} align="center">
        <div className={classes.icon}>{icon}</div>
        <p>{title}</p>
      </Flex>
      <Switch
        defaultChecked={defaultChecked}
        onChange={onChange}
        className={classes.switcher}
      />
    </Flex>
  );
};
