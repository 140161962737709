import { Flex, Input, Button } from "antd";
import { QuadBikeReqT } from "../../../api/requests";
import useMessages from "../../../hooks/useMessages";
import React, { useState } from "react";
import { useApiRequest } from "../../../hooks/useApiRequests";
import { messageResT } from "../../../api/responses";
import { updatePriceManagementQuadBike } from "../../../api/services/priceManagementServices";

const QuadBikeUpdatePrice: React.FC<
  {
    getData: () => void;
  } & QuadBikeReqT
> = ({ _id, quad_bike, buggy_2, buggy_3, getData }) => {
  const [editValues, setEditValues] = useState<Omit<QuadBikeReqT, "_id">>({
    quad_bike,
    buggy_2,
    buggy_3,
  });
  const [isEdit, setIsEdit] = useState<string[]>([]);
  const { success, error, contextHolder } = useMessages();

  const [, makeRequest] = useApiRequest<messageResT>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateConfirm = () => {
    try {
      makeRequest(
        updatePriceManagementQuadBike(
          {
            quad_bike: Number(editValues.quad_bike),
            buggy_2: Number(editValues.buggy_2),
            buggy_3: Number(editValues.buggy_3),
          },
          _id
        ),
        (e) => {
          success(e.message);
          setIsEdit([]);
          getData();
        }
      );
    } catch (err) {
      error("Something went wrong");
    }
  };

  return (
    <>
      {contextHolder}
      <Flex gap={24} vertical align="flex-start">
        <Flex gap={24} vertical>
          <h2>Quad Bike</h2>
          <Flex gap={12} align="center">
            <h3>Quad Bike Price:</h3>
            {isEdit.includes("quad_bike") ? (
              <Input
                value={editValues.quad_bike}
                onChange={handleChange}
                name="quad_bike"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{quad_bike}₾</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("quad_bike")) {
                      return prev.filter((e) => e !== "quad_bike");
                    }
                    return [...prev, "quad_bike"];
                  })
                }
              >
                {isEdit.includes("quad_bike") ? "Cancel" : "Edit"}
              </Button>
            </Flex>
          </Flex>

          <Flex gap={12} align="center">
            <h3>2 Person Buggy Price:</h3>
            {isEdit.includes("buggy_2") ? (
              <Input
                value={buggy_2}
                onChange={handleChange}
                name="buggy_2"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{buggy_2}₾</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("buggy_2")) {
                      return prev.filter((e) => e !== "buggy_2");
                    }
                    return [...prev, "buggy_2"];
                  })
                }
              >
                {isEdit.includes("buggy_2") ? "Cancel" : "Edit"}
              </Button>
            </Flex>
          </Flex>

          <Flex gap={12} align="center">
            <h3>3 Person Buggy Price:</h3>
            {isEdit.includes("buggy_3") ? (
              <Input
                value={buggy_3}
                onChange={handleChange}
                name="buggy_3"
                style={{ maxWidth: 120 }}
              />
            ) : (
              <p>{buggy_3}₾</p>
            )}

            <Flex gap={8} align="center">
              <Button
                onClick={() =>
                  setIsEdit((prev) => {
                    if (prev.includes("buggy_3")) {
                      return prev.filter((e) => e !== "buggy_3");
                    }
                    return [...prev, "buggy_3"];
                  })
                }
              >
                {isEdit.includes("buggy_3") ? "Cancel" : "Edit"}
              </Button>
            </Flex>
          </Flex>
        </Flex>
        {isEdit.length !== 0 && (
          <Button
            type="primary"
            style={{ maxWidth: 137 }}
            onClick={handleUpdateConfirm}
          >
            Confirm Updates
          </Button>
        )}
      </Flex>
    </>
  );
};

export default QuadBikeUpdatePrice;
