import React from "react";
import { useMountedState } from "./useMountedState";
import { ErrorResponse } from "../api/responses";

type RequestStateSuccess<T> = {
  type: "REQUEST_SUCCESS";
  response: T;
  headers?: Record<string, string | undefined | null>;
};

export const isSucceeded = <T>(
  state: RequestState<T>
): state is RequestStateSuccess<T> => state.type === "REQUEST_SUCCESS";

export const isLoading = <T>(
  state: RequestState<T>
): state is ReturnType<typeof requestError> => state.type === "REQUEST_START";

export const isNotFinished = <T>(
  state: RequestState<T>
): state is ReturnType<typeof requestError> =>
  state.type === "REQUEST_START" || state.type === "REQUEST_INIT";

export const isFailed = <T>(
  state: RequestState<T>
): state is ReturnType<typeof requestError> => state.type === "REQUEST_ERROR";
export const requestInit = () =>
  ({ type: "REQUEST_INIT", response: null } as const);
export const requestStart = () =>
  ({ type: "REQUEST_START", response: null } as const);
export const requestSuccess = <T>(
  response: T,
  headers?: Record<string, string | undefined | null>
): RequestStateSuccess<T> =>
  ({ type: "REQUEST_SUCCESS", response, headers } as const);
export const requestError = (error: ErrorResponse) =>
  ({ type: "REQUEST_ERROR", error, response: null } as const);
export const responseOrUndefined = <T>(
  request: RequestState<T>
): T | undefined =>
  request.type === "REQUEST_SUCCESS" ? request.response : undefined;
export type RequestState<T> =
  | ReturnType<typeof requestInit | typeof requestStart | typeof requestError>
  | RequestStateSuccess<T>;

export const useRequestState = <T>() => {
  const isMounted = useMountedState();
  const [requestState, setRequestState] = React.useState<RequestState<T>>(
    requestInit()
  );
  const setRequestStateCallback = React.useCallback(
    (state: RequestState<T>) => {
      if (isMounted()) {
        setRequestState(state);
      }
    },
    [isMounted]
  );
  return [requestState, setRequestStateCallback] as const;
};
