import { FaqSectionProps } from "../../../../types";
import useMessages from "../../../../hooks/useMessages";
import React, { useState } from "react";
import { Button, Flex, Input, Modal, Select } from "antd";
import Collapse from "../../../../components/Collapse";
import { useApiRequest } from "../../../../hooks/useApiRequests";
import { messageResT } from "../../../../api/responses";
import {
  createContactPageFaqQuestion,
  createContactPageFaqTitle,
  deleteContactPageFaqQuestion,
  deleteContactPageFaqTitle,
  updateContactPageFaqQuestion,
  updateContactPageFaqTitle,
} from "../../../../api/services";
import { faqTitleT } from "../../../../api/responses/contactPageResponses";
import { deleteModal } from "../../../../components/DeleteModal";
import classes from "./faqSection.module.css";

const FAQSection: React.FC<FaqSectionProps> = ({
  faqData,
  faqQuestions,
  faqTitles,
  getData,
}) => {
  const [title, setTitle] = useState<faqTitleT>();
  const [loading, setLoading] = useState<boolean>(false);
  const [titleId, setTitleId] = useState<string>();
  const [qa, setQa] = useState<{
    _id: string;
    question: string;
    answer: string;
  }>();
  const [isQaModalOpen, setIsQaModalOpen] = useState<boolean>(false);
  const [isQaCreateModalOpen, setIsQaCreateModalOpen] =
    useState<boolean>(false);
  const [isTitleModalOpen, setIsTitleModalOpen] = useState<boolean>(false);
  const [isTitleCreateModalOpen, setIsTitleCreateModalOpen] =
    useState<boolean>(false);
  const { error, success, contextHolder } = useMessages();

  const [, makeRequest] = useApiRequest<messageResT>();

  const handleTitleModalOpen = (id: string) => {
    const qaTitle = faqTitles.find((e) => e._id === id);
    if (qaTitle) {
      setTitle(qaTitle);
      setIsTitleModalOpen(true);
    }
  };

  const handleTitleCreateModalOpen = () => {
    setTitle({ _id: "", title: "" } as faqTitleT);
    setIsTitleCreateModalOpen(true);
  };

  const handleTitleCreateModalCancel = () => {
    setIsTitleCreateModalOpen(false);
    setTitle(undefined);
  };

  const handleTitleModalCancel = () => {
    setIsTitleModalOpen(false);
    setTitle(undefined);
  };

  const handleTitleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTitle(
      (prev) =>
        ({
          ...prev,
          title: value,
        } as faqTitleT)
    );
  };

  const handleQaCreateModalOpen = () => {
    setQa({ _id: "", question: "", answer: "" });
    setIsQaCreateModalOpen(true);
  };

  const handleQaCreateModalCancel = () => {
    setIsQaCreateModalOpen(false);
    setQa(undefined);
    setTitleId(undefined);
  };

  const handleQaModalOpen = (id: string) => {
    const questionAnswer = faqQuestions.find((e) => e._id === id);
    if (questionAnswer) {
      setQa({
        _id: questionAnswer._id,
        question: questionAnswer.question,
        answer: questionAnswer.answer,
      });
      setTitleId(questionAnswer.titleId);
      setIsQaModalOpen(true);
    }
  };

  const handleQaModalCancel = () => {
    setIsQaModalOpen(false);
    setQa(undefined);
    setTitleId(undefined);
  };

  const handleQaTextareaChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value } = e.target;

    setQa(
      (prev) =>
        ({
          ...prev,
          answer: value,
        } as { _id: string; question: string; answer: string } | undefined)
    );
  };

  const handleQaInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQa(
      (prev) =>
        ({
          ...prev,
          question: value,
        } as { _id: string; question: string; answer: string } | undefined)
    );
  };

  const onQuestionCreateConfirm = () => {
    try {
      setLoading(true);
      if (!qa) {
        return error("Please enter question and answer");
      }
      if (qa.question.trim() === "" || qa.answer.trim() === "") {
        return error("Question and answer cannot be empty");
      }
      if (!titleId) return error("Please select a title");

      makeRequest(
        createContactPageFaqQuestion({
          question: qa.question,
          answer: qa.answer,
          titleId,
        }),
        (e) => {
          success(e.message);
          setIsQaCreateModalOpen(false);
          setQa(undefined);
          setTitleId(undefined);
          getData();
          setLoading(false);
        }
      );
    } catch (err) {
      error("Something went wrong");
    }
  };

  const onQuestionUpdateConfirm = () => {
    try {
      setLoading(true);
      if (!qa) {
        return error("Please enter question and answer");
      }
      if (qa.question.trim() === "" || qa.answer.trim() === "") {
        return error("Question and answer cannot be empty");
      }
      if (!titleId) return error("Please select a title");

      makeRequest(
        updateContactPageFaqQuestion({ ...qa, titleId }, qa._id),
        (e) => {
          success(e.message);
          setIsQaModalOpen(false);
          setQa(undefined);
          setTitleId(undefined);
          getData();
          setLoading(false);
        }
      );
    } catch (err) {
      error("Something went wrong");
    }
  };

  const onTitleCreateConfirm = () => {
    try {
      setLoading(true);
      if (!title) return error("Please enter a title");

      if (title.title.trim() === "") return error("Title cannot be empty");
      makeRequest(createContactPageFaqTitle({ title: title.title }), (e) => {
        success(e.message);
        setIsTitleCreateModalOpen(false);
        setTitle(undefined);
        getData();
        setLoading(false);
      });
    } catch (err) {
      error("Something went wrong");
    }
  };

  const onTitleUpdateConfirm = () => {
    try {
      setLoading(true);
      if (!title) return error("Something went wrong");

      if (title.title.trim() === "") return error("Title cannot be empty");

      makeRequest(updateContactPageFaqTitle({ ...title }, title._id), (e) => {
        success(e.message);
        setIsTitleModalOpen(false);
        setTitle(undefined);
        getData();
        setLoading(false);
      });
    } catch (err) {
      error("Something went wrong");
    }
  };

  const handleTitleDelete = (id: string) => {
    try {
      setLoading(true);
      makeRequest(deleteContactPageFaqTitle(id), (e) => {
        success(e.message);
        getData();
        setLoading(false);
      });
    } catch (err) {
      error("Something went wrong");
    }
  };

  const handleQaDelete = (id: string) => {
    try {
      setLoading(true);
      makeRequest(deleteContactPageFaqQuestion(id), (e) => {
        success(e.message);
        getData();
        setLoading(false);
      });
    } catch (err) {
      error("Something went wrong");
    }
  };

  const onTitleDelete = (id: string) => {
    deleteModal(
      () => handleTitleDelete(id),
      "Are you sure you want to delete this title, it will delete all of the questions and answers with this title!"
    );
  };

  const onQaDelete = (id: string) => {
    deleteModal(
      () => handleQaDelete(id),
      "Are you sure you want to delete this?!"
    );
  };

  const titleSelectData = faqTitles.map((e) => ({
    value: e._id,
    label: e.title,
  }));

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={24}>
          <h2>FAQ Section</h2>

          <Flex gap={8} style={{ maxWidth: 337, width: "100%" }}>
            <Button type="primary" onClick={handleTitleCreateModalOpen}>
              Add Title
            </Button>
            <Button type="primary" onClick={handleQaCreateModalOpen}>
              Add Question
            </Button>
          </Flex>

          {faqData.map(({ title, items, _id }) => (
            <Collapse
              handleTitleOpen={() => handleTitleModalOpen(_id)}
              handleTitleDeleteOpen={() => onTitleDelete(_id)}
              key={_id}
              title={title}
            >
              <Flex vertical gap={12} style={{ marginTop: 24 }}>
                {items.map(({ question, answer, _id }) => (
                  <Collapse
                    inner
                    handleChildrenOpen={() => handleQaModalOpen(_id)}
                    handleChildrenDeleteOpen={() => onQaDelete(_id)}
                    key={_id}
                    title={question}
                  >
                    {answer.split("\n").map((e, i) => (
                      <p key={i}>{e}</p>
                    ))}
                  </Collapse>
                ))}
              </Flex>
            </Collapse>
          ))}

          <Modal
            title={"Create FAQ Title"}
            open={isTitleCreateModalOpen}
            onCancel={handleTitleCreateModalCancel}
            onOk={onTitleCreateConfirm}
            okButtonProps={{ loading: loading }}
            className={classes.editModal}
          >
            <Flex vertical gap={8}>
              <p>Title: </p>
              <Input value={title?.title} onChange={handleTitleInputChange} />
            </Flex>
          </Modal>

          <Modal
            title="Create Question"
            open={isQaCreateModalOpen}
            onCancel={handleQaCreateModalCancel}
            onOk={onQuestionCreateConfirm}
            okButtonProps={{ loading: loading }}
            className={classes.editModal}
          >
            <Flex vertical gap={12} style={{ width: "100%" }}>
              <Flex align="center" gap={10} style={{ width: "100%" }}>
                <p>Select title: </p>
                <Select
                  options={titleSelectData}
                  value={titleId}
                  onChange={setTitleId}
                  style={{ maxWidth: 180, width: "100%" }}
                />
              </Flex>
              <Flex vertical gap={8}>
                <p>Question: </p>
                <Input value={qa?.question} onChange={handleQaInputChange} />
              </Flex>
              <Flex vertical gap={8}>
                <p>Answer: </p>
                <textarea
                  value={qa?.answer}
                  rows={10}
                  onChange={handleQaTextareaChange}
                  style={{ padding: "5px 10px", outline: "none" }}
                />
              </Flex>
            </Flex>
          </Modal>

          <Modal
            title="Update Question"
            onCancel={handleQaModalCancel}
            open={isQaModalOpen}
            onOk={onQuestionUpdateConfirm}
            okButtonProps={{ loading: loading }}
            className={classes.editModal}
          >
            <Flex vertical gap={12} style={{ width: "100%" }}>
              <Flex align="center" gap={10} style={{ width: "100%" }}>
                <p>Select title: </p>
                <Select
                  options={titleSelectData}
                  value={titleId}
                  onChange={setTitleId}
                  style={{ maxWidth: 180, width: "100%" }}
                />
              </Flex>
              <Flex vertical gap={8}>
                <p>Question: </p>
                <Input value={qa?.question} onChange={handleQaInputChange} />
              </Flex>
              <Flex vertical gap={8}>
                <p>Answer: </p>
                <textarea
                  value={qa?.answer}
                  rows={10}
                  onChange={handleQaTextareaChange}
                  style={{ padding: "5px 10px", outline: "none" }}
                />
              </Flex>
            </Flex>
          </Modal>

          <Modal
            title="Update Title"
            open={isTitleModalOpen}
            onCancel={handleTitleModalCancel}
            onOk={onTitleUpdateConfirm}
            okButtonProps={{ loading }}
            className={classes.editModal}
          >
            <Flex vertical gap={8}>
              <p>Title: </p>
              <Input value={title?.title} onChange={handleTitleInputChange} />
            </Flex>
          </Modal>
        </Flex>
      </section>
    </>
  );
};

export default FAQSection;
