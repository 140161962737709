import { Flex } from "antd";
import React, { useState } from "react";
import {
  messageResT,
  updateHomePageBanner,
  updateHomePagePopup,
} from "src/api";
import MediaCard from "src/components/MediaCard/MediaCard";
import MediaCardsWrapper from "src/components/MediaCardsWrapper/MediaCardsWrapper";
import MediaEditModal from "src/components/MediaEditModal/MediaEditModal";
import { useApiRequest } from "src/hooks/useApiRequests";
import useMessages from "src/hooks/useMessages";
import { cloudinaryUpload } from "src/lib/cloudinaryUpload";
import { MediaDataT, PopupSectionProps } from "src/types";

const PopupSection: React.FC<PopupSectionProps> = ({ data, getData }) => {
  const { _id, title, subtitle, image } = data;
  const [editCouponDetail, setEditCouponDetail] = useState<MediaDataT>();
  const [isEditCouponModalOpen, setIsEditCouponModalOpen] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { error, success, contextHolder } = useMessages();

  const [, makeUpdateRequest] = useApiRequest<messageResT>();

  const showEditCouponModal = () => {
    setIsEditCouponModalOpen(true);
    setEditCouponDetail(data);
  };

  const handleOkEditCoupon = () => {
    if (editCouponDetail) {
      try {
        makeUpdateRequest(
          updateHomePagePopup(
            {
              ...editCouponDetail,
            },
            editCouponDetail._id
          ),
          (e) => {
            setIsEditCouponModalOpen(false);
            success(e.message);
            getData();
          }
        );
      } catch (err) {
        error("Something went wrong");
      }
    }
  };

  const handleCancelEditCoupon = () => {
    setIsEditCouponModalOpen(false);
  };

  const handleCouponFieldChange = async (fieldName: string, value: string) => {
    let image: any;
    setLoading(true);
    if (value && fieldName === "image") {
      image = await cloudinaryUpload(value);
    }
    setEditCouponDetail(
      (prev) =>
        ({
          ...prev,
          [fieldName]: fieldName === "image" ? image : value,
        } as MediaDataT)
    );
    setLoading(false);
  };

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={12}>
          <h2>Popup</h2>
          <MediaCard
            data={{
              _id: _id,
              image: image,
              title: title,
              subtitle: subtitle,
            }}
            onEdit={showEditCouponModal}
          />
        </Flex>

        <MediaEditModal
          isMediaEditModalOpen={isEditCouponModalOpen}
          handleOk={handleOkEditCoupon}
          handleCancel={handleCancelEditCoupon}
          handleFieldChange={handleCouponFieldChange}
          mediaDetails={editCouponDetail}
          isLoading={loading}
        />
      </section>
    </>
  );
};

export default PopupSection;
