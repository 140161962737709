import { Flex, Skeleton } from "antd";
import classes from "./dashboardSkeleton.module.css";

export const DashboardSkeleton = () => {
  const orderAmountCards = Array.from({ length: 4 }, (_, index) => (
    <Skeleton.Input
      key={index}
      active
      size="large"
      block
      className={classes.amountsCard}
    />
  ));
  const orderNumberCards = Array.from({ length: 4 }, (_, index) => (
    <Skeleton.Input
      key={index}
      active
      size="large"
      block
      className={classes.ordersCard}
    />
  ));
  return (
    <Flex vertical gap={36}>
      <Skeleton.Input active />
      <Flex gap={16} className={classes.cardsWrapper}>
        {orderAmountCards}
      </Flex>
      <Flex gap={16} className={classes.cardsWrapper}>
        {orderNumberCards}
      </Flex>
      <Skeleton active paragraph={{ rows: 8 }} />
      <Skeleton active paragraph={{ rows: 8 }} />
    </Flex>
  );
};
