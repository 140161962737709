// ========== GET ENDPOINTS ========== //
export const getContactPageEndpoint = () => {
  return "/contactPage";
};

// ========== POST ENDPOINTS ========== //

// Banner
export const postContactPageBannerEndpoint = () => {
  return "/contactPage/banner";
};

// FAQ Title
export const postContactPageFAQTitleEndpoint = () => {
  return `/contactPage/faqTitle`;
};

// FAQ Question
export const postContactPageFAQQuestionEndpoint = () => {
  return `/contactPage/faqQuestion`;
};

// Carousel Image
export const postContactPageCarouselImageEndpoint = () => {
  return "/contactPage/carouselImage";
};

// ========== PUT ENDPOINTS ========== //

// Banner
export const putContactPageBannerEndpoint = (id: string) => {
  return `/contactPage/banner/${id}`;
};

// FAQ Title
export const putContactPageFAQTitleEndpoint = (id: string) => {
  return `/contactPage/faqTitle/${id}`;
};

// FAQ Question
export const putContactPageFAQQuestionEndpoint = (id: string) => {
  return `/contactPage/faqQuestion/${id}`;
};

// Carousel Image
export const putContactPageCarouselImageEndpoint = (id: string) => {
  return `/contactPage/carouselImage/${id}`;
};

// ========== DELETE ENDPOINTS ========== //

// Banner
export const deleteContactPageBannerEndpoint = (id: string) => {
  return `/contactPage/banner/${id}`;
};

// FAQ Title
export const deleteContactPageFAQTitleEndpoint = (id: string) => {
  return `/contactPage/faqTitle/${id}`;
};

// FAQ Question
export const deleteContactPageFAQQuestionEndpoint = (id: string) => {
  return `/contactPage/faqQuestion/${id}`;
};

// Carousel Image
export const deleteContactPageCarouselImageEndpoint = (id: string) => {
  return `/contactPage/carouselImage/${id}`;
};
