import React, { useState } from "react";
import styles from "./collapseStyles.module.css";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { IoChevronDownSharp } from "react-icons/io5";
import { Button, Flex } from "antd";

interface CollapseProps {
  title: string;
  children: React.ReactNode;
  inner?: true;
  defaultCollapsed?: true;
  handleChildrenOpen?: () => void;
  handleTitleOpen?: () => void;
  handleChildrenDeleteOpen?: () => void;
  handleTitleDeleteOpen?: () => void;
}

const Collapse: React.FC<CollapseProps> = ({
  title,
  children,
  inner,
  defaultCollapsed,
  handleChildrenOpen,
  handleTitleOpen,
  handleChildrenDeleteOpen,
  handleTitleDeleteOpen,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed || false);
  const [onEditBtn, setOnEditBtn] = useState<boolean>(false);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={styles["collapse"]}>
      <div
        className={styles["collapse__header"]}
        onClick={!onEditBtn ? toggleCollapse : undefined}
      >
        {!inner ? (
          <IoChevronDownSharp
            width={14}
            height={7}
            style={{
              transform: ` ${isCollapsed ? "rotate(3.142rad)" : "rotate(0)"}`,
              cursor: "pointer",
            }}
          />
        ) : isCollapsed ? (
          <div
            style={{
              minWidth: 14,
              minHeight: 14,
            }}
          >
            <FaMinus width={14} height={14} />
          </div>
        ) : (
          <div
            style={{
              minWidth: 14,
              minHeight: 14,
            }}
          >
            <FaPlus width={14} height={14} />
          </div>
        )}
        <Flex justify="space-between" align="center" style={{ width: "100%" }}>
          <span
            className={`${
              !inner
                ? styles["collapse__header--title"]
                : styles["collapse__header--innerTitle"]
            }`}
          >
            {title}
          </span>

          <Flex gap={8} align="center">
            <Button
              onClick={inner ? handleChildrenOpen : handleTitleOpen}
              onMouseEnter={() => setOnEditBtn(true)}
              onMouseLeave={() => setOnEditBtn(false)}
            >
              Edit <strong>{inner ? " Question" : " Title"}</strong>
            </Button>
            <Button
              onClick={inner ? handleChildrenDeleteOpen : handleTitleDeleteOpen}
              onMouseEnter={() => setOnEditBtn(true)}
              onMouseLeave={() => setOnEditBtn(false)}
              type="primary"
              danger
            >
              Delete <strong>{inner ? " Question" : "Title"}</strong>
            </Button>
          </Flex>
        </Flex>
      </div>
      {isCollapsed && (
        <div className={`${inner ? styles["collapse__content"] : ""}`}>
          {children}
        </div>
      )}
    </div>
  );
};

export default Collapse;
