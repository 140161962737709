export const getCouponsEndpoint = () => {
  return `/coupon`;
};

export const postCouponEndpoint = () => {
  return `/coupon`;
};

export const putCouponEndpoint = (id: string) => {
  return `/coupon/${id}`;
};

export const deleteCouponEndpoint = (id: string) => {
  return `/coupon/${id}`;
};

export const postCouponApplyEndpoint = () => {
  return `/coupon/apply`;
};
