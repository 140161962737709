import { useState } from "react";
import { Flex } from "antd";
import MediaCard from "../../../MediaCard/MediaCard";
import MediaEditModal from "../../../MediaEditModal/MediaEditModal";
import { MediaDataT } from "../../../../types/mediaType";
import { WelcomeSecondProps } from "../../../../types/component-types/homepageProps";
import { useApiRequest } from "../../../../hooks/useApiRequests";
import { messageResT } from "../../../../api/responses";
import useMessages from "../../../../hooks/useMessages";
import { updateHomepageWelcomeSecondSection } from "../../../../api/services/homePageServices";
import { cloudinaryUpload } from "../../../../lib/cloudinaryUpload";

const WelcomeSecondSection: React.FC<WelcomeSecondProps> = ({
  welcomeSecondData,
  getData,
}) => {
  const { _id, title, subtitle, image } = welcomeSecondData;
  const [editWelcomeSecondDetail, setEditWelcomeSecondDetail] =
    useState<MediaDataT>();
  const [isEditWelcomeSecondModalOpen, setIsEditWelcomeSecondModalOpen] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [, makeUpdateRequest] = useApiRequest<messageResT>();

  const { error, success, contextHolder } = useMessages();

  const showEditWelcomeSecondModal = () => {
    setIsEditWelcomeSecondModalOpen(true);
    setEditWelcomeSecondDetail(welcomeSecondData);
  };

  const handleOkEditWelcomeSecond = () => {
    if (editWelcomeSecondDetail) {
      try {
        makeUpdateRequest(
          updateHomepageWelcomeSecondSection(
            {
              ...editWelcomeSecondDetail,
            },
            editWelcomeSecondDetail._id
          ),
          (e) => {
            setIsEditWelcomeSecondModalOpen(false);
            success(e.message);
            getData();
          }
        );
      } catch (err) {
        error("Something went wrong");
      }
    }
  };

  const handleCancelEditWelcomeSecond = () => {
    setIsEditWelcomeSecondModalOpen(false);
  };

  const handleWelcomeSecondFieldChange = async (
    fieldName: string,
    value: string
  ) => {
    let image: any;
    setLoading(true);
    if (value && fieldName === "image") {
      image = await cloudinaryUpload(value);
    }

    setEditWelcomeSecondDetail(
      (prev) =>
        ({
          ...prev,
          [fieldName]: fieldName === "image" ? image : value,
        } as MediaDataT)
    );
    setLoading(false);
  };

  const paragraphs = subtitle.split("\n");
  const paragraphsWithBreaks = paragraphs.map((paragraph, index) => (
    <h4 key={index}>{paragraph}</h4>
  ));

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={12}>
          <h2>Welcome to Gudauri - Georgia's Alpine Wonderland</h2>
          <MediaCard
            data={{
              _id: _id,
              image: image,
              title: title,
              subtitle: paragraphsWithBreaks,
            }}
            onEdit={showEditWelcomeSecondModal}
          />
        </Flex>

        <MediaEditModal
          isMediaEditModalOpen={isEditWelcomeSecondModalOpen}
          handleOk={handleOkEditWelcomeSecond}
          handleCancel={handleCancelEditWelcomeSecond}
          handleFieldChange={handleWelcomeSecondFieldChange}
          mediaDetails={editWelcomeSecondDetail}
          isLoading={loading}
          useTextAreaForSubtitleEdit
        />
      </section>
    </>
  );
};

export default WelcomeSecondSection;
