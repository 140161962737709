import { useEffect, useState } from "react";
import GudauriContent from "../../components/ContentManagement/AboutPage/GudauriContent/GudauriContent";
import { GetGudauriPageResT } from "../../api/responses/aboutPageResponses";
import { getGudauriPage } from "../../api/services/aboutPageServices/gudauriServices";
import { useApiRequest } from "../../hooks/useApiRequests";

const GudauriPageContainer = () => {
  const [data, setData] = useState<GetGudauriPageResT>();

  const [, makeRequest] = useApiRequest<GetGudauriPageResT>();

  const getData = () => {
    makeRequest(getGudauriPage(), (data) => {
      setData(data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  if (!data) return <p>Loading...</p>;

  return <GudauriContent data={data} getData={getData} />;
};

export default GudauriPageContainer;
