import { Button, Flex } from "antd";
import React, { useState } from "react";
import { CouponManagementProps } from "src/types/component-types/couponManagement";
import CouponCard from "./coupon-card/CouponCard";
import { CouponRequestT, messageResT } from "src/api";
import CouponModal from "./coupon-modal/CouponModal";
import { useApiRequest } from "src/hooks/useApiRequests";
import { createCoupon } from "src/api/services/couponServices";
import useMessages from "src/hooks/useMessages";

const CouponManagement: React.FC<CouponManagementProps> = ({
  data,
  getData,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<CouponRequestT>();

  const { success, error, contextHolder } = useMessages();
  const [, makeRequest] = useApiRequest<messageResT>();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCreate = () => {
    if (editItem) {
      try {
        makeRequest(createCoupon(editItem), (e) => {
          success(e.message);
          setOpen(false);
          getData();
          setEditItem(undefined);
        });
      } catch (err) {
        error("Something went wrong");
      }
    }
  };

  const handleFieldChange = (dataIndex: string, value: string | number) => {
    setEditItem((prev) => ({
      ...prev,
      [dataIndex]: value,
    }));
  };

  const couponsRenderer = () => {
    if (data.length === 0)
      return <p>There is no coupon available. Create new one</p>;

    return data.map((e) => <CouponCard {...e} key={e._id} getData={getData} />);
  };

  return (
    <>
      {contextHolder}
      <CouponModal
        open={open}
        data={editItem}
        handleOk={handleCreate}
        handleCancel={() => setOpen(false)}
        handleFieldChange={handleFieldChange}
        customTitle={"Create Coupon"}
      />
      <Flex vertical gap={80}>
        <Flex justify="space-between" align="center">
          <h1>Coupon Management</h1>
          <Button type="primary" onClick={handleOpen}>
            Add Coupon
          </Button>
        </Flex>

        {couponsRenderer()}
      </Flex>
    </>
  );
};

export default CouponManagement;
