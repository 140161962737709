import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { GetCouponsResT } from "src/api/responses/couponResponses";
import { getCoupons } from "src/api/services/couponServices";
import CouponManagement from "src/components/coupon-mamagement/CouponManagement";
import { useApiRequest } from "src/hooks/useApiRequests";

const CouponContainer = () => {
  const [data, setData] = useState<GetCouponsResT[]>();

  const [, makeRequest] = useApiRequest<GetCouponsResT[]>();

  const getData = () => {
    makeRequest(getCoupons(), (e) => {
      setData(e);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  if (!data) return <Skeleton active />;

  return <CouponManagement data={data} getData={getData} />;
};

export default CouponContainer;
