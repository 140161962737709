import { SEOT } from "../../types";
import { getAxiosClient } from "../client";
import {
  getSEOSettingsEndpoint,
  updateSEOSettingsEndpoint,
} from "../endpoints";
import { makeApiRequest } from "../requests";
import { ApiResponse, messageResT } from "../responses";
import { GetSEOResT } from "../responses/seoOptimizationResponses";

// ========== GET ========== //
export const getSEOData = (): Promise<ApiResponse<GetSEOResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).get(
    getSEOSettingsEndpoint()
  );
  const result = makeApiRequest<GetSEOResT>(httpRequest);

  return result;
};

// ========== PUT =========== //
export const updateSEOSettings = (
  data: Omit<SEOT, "_id">,
  id: string
): Promise<ApiResponse<messageResT>> => {
  const token = localStorage.getItem("token");

  if (!token) return Promise.reject("Token not found");

  const httpRequest = getAxiosClient(token, undefined).put(
    updateSEOSettingsEndpoint(id),
    data
  );
  const result = makeApiRequest<messageResT>(httpRequest);

  return result;
};
